import { Divider, Grid, Typography } from '@material-ui/core';
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { useCallback, useEffect, useRef, useState } from 'react';
import CardCarrinhoGrupoProdutos from 'views/components/cards/card-carrinho-grupo-produtos/card-carrinho-grupo-produtos';
import CardCarrinho from 'views/components/cards/card-carrinho/card-carrinho';
import { useStyles } from './carrinho-list-data-styles';
import { CircularLoading } from 'views/components';
import classNames from 'classnames';
import { useAgruparProdutos } from 'services/app/hooks/agrupar-produtos';
import { CarrinhoVazio } from '../carrinho-vazio';
import { useContratoAtual } from 'services/app/hooks/contrato-atual';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { usePedidoDelivery } from 'services/app/hooks/pedido-delivery';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { usePDV } from 'services/app/hooks/pdv';
import { EnumPDVConfigCod } from 'model/enums/enum-pdv-config';
import { EnumModeloDeTrabalho } from 'model/enums/enum-modelo-de-trabalho';

export interface CarrinhoGridProps {
  list: Array<MovSimplesProdutoModel>;
  showCancelados: boolean;
  inativarProduto(model: MovSimplesProdutoModel): Promise<void>;
  reativarProduto(model: MovSimplesProdutoModel): Promise<void>;
  alterarQtdeProduto(
    model: MovSimplesProdutoModel,
    incremento: number,
    fator: 'sub' | 'add'
  ): Promise<void>;
  removerPedidoDoCarrinho(idPedido: string): Promise<void>;
  setProductEdit: (produtoId: string) => void;
}
export interface CarrinhoListPedido {
  pedidoId: string;
  comandaId: string;
  mesaId: string;
  codigoPedido: string;
  identificador: string;
  produtos: MovSimplesProdutoModel[];
}
export const CarrinhoListData = ({
  list,
  showCancelados,
  inativarProduto,
  reativarProduto,
  alterarQtdeProduto,
  removerPedidoDoCarrinho,
  setProductEdit
}: CarrinhoGridProps) => {
  // STATES E REFS
  const [visibleItems, setVisibleItems] = useState(15);
  const divRef = useRef<HTMLDivElement>(null);
  const [carregando, setCarregando] = useState<boolean>(true);
  const { isPedidoDelivery } = usePedidoDelivery();

  // HOOKS
  const { agruparProdutosPorAdicionais, agruparProdutosPorPedido } =
    useAgruparProdutos(list ?? []);
  const { getMov } = useMovAtual()
  const { getConfigByCod } = useContratoAtual();
  const prodTaxaServicoId = getConfigByCod(EnumContratoConfig.ProdutoServico);
  const { getConfigByCod: getConfigPDV } = usePDV()

  // AUX
  const classes = useStyles();

  const isFinalizacaoVenda = useCallback(() => {
    const apenasCaixa = getConfigPDV(EnumPDVConfigCod.ModeloTrabalho) === EnumModeloDeTrabalho.APENAS_CAIXA
    const isFinalizacaoPedido = (getMov()?.informacoesGeraisPedido.pedidos ?? []).length > 0

    if (apenasCaixa) return true
    if (isFinalizacaoPedido) return true

    return false
  }, [getConfigPDV, getMov])

  useEffect(() => {
    const div = divRef.current;
    const idTimeaout = setTimeout(() => {
      setCarregando(false);
    }, 1350);

    const handleScroll = () => {
      if (div && div.scrollTop + div.clientHeight + 10 >= div.scrollHeight) {
        setVisibleItems((prevVisibleItems) => prevVisibleItems + 10);
      }
    };

    div?.addEventListener('scroll', handleScroll);

    return () => {
      if (div) {
        div.removeEventListener('scroll', handleScroll);
      }
      clearTimeout(idTimeaout);
    };
  }, []);

  const pedidos = agruparProdutosPorPedido();
  const produtosComAdicionais = agruparProdutosPorAdicionais();
  return (
    <>
      {!carregando &&
        list.filter((produto) => {
          if (!(prodTaxaServicoId === produto.produtoId)) {
            if (!showCancelados) {
              return produto.ativo;
            } else {
              return produto;
            }
          }

          return false;
        }).length === 0 && <CarrinhoVazio />}
      <Grid container>
        <Grid
          item
          xs={12}
          className={classNames(
            classes.listContainer,
            isFinalizacaoVenda() ? classes.definirTamanhoVenda : classes.definirTamanhoPedido,
            isPedidoDelivery() ? classes.definirTamanhoDelivery : undefined,
          )}
          ref={divRef}
        >
          {carregando && <CircularLoading tipo="FULLSIZED" />}



          {/* Listagem de produtos por pedidos */}
          {!carregando && pedidos.length > 0 ? (
            pedidos.map((item) => {
              return (
                <CardCarrinhoGrupoProdutos
                  inativarProduto={inativarProduto}
                  reativarProduto={reativarProduto}
                  model={item}
                  key={item.pedidoId}
                  showCancelados={showCancelados}
                  removerPedidoDoCarrinho={removerPedidoDoCarrinho}
                />
              );
            })
          ) : (
            <></>
          )}

          {/* Listagem de produtos de venda sem pedido atrelado */}
          {!carregando && produtosComAdicionais.length > 0 ? (
            produtosComAdicionais
              .sort((a, b) => b.nSeq - a.nSeq)
              .slice(0, visibleItems)
              .map((item, index) => {
                return (
                  <div
                    key={item.id}
                    style={{
                      display: !showCancelados && !item.ativo ? 'none' : 'block'
                    }}
                  >
                    {(index === 0 ||
                      produtosComAdicionais[index - 1].vendedorId !==
                      item.vendedorId) && (
                        <div className={classes.vendedorContent}>
                          <Typography className={classes.vendedorText}>
                            Vendedor: <b>{item.vendedorNome}</b>
                          </Typography>
                          <Divider className={classes.vendedorDivider} />
                        </div>
                      )}
                    <CardCarrinho
                      inativarProduto={inativarProduto}
                      reativarProduto={reativarProduto}
                      alterarQtdeProduto={alterarQtdeProduto}
                      key={item.id}
                      model={item}
                      setProductEdit={setProductEdit}
                    />
                  </div>
                );
              })
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </>
  );
};
