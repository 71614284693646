import { guidEmpty } from "utils/guid-empty";

export class MovRegistrarPagadorDadosModel{
    constructor(
      public nome: string = '',
      public cpfCnpj: string = '',
      public endereco: string = '',
      public numero: string = '',
      public complemento: string = '',
      public bairro: string = '',
      public cidade: string = '',
      public uf: number = 11,
      public cep: string = '',
      public email: string = '',
      public fone: string = ''
    ){}
}

export class MovRegistrarPagadorModel {
    constructor(
        public pessoaId: string = '',
        public dadosPessoa: MovRegistrarPagadorDadosModel = new MovRegistrarPagadorDadosModel(),
    ) { }
}

export class MovRegistrarParcelasModel{
    constructor(
        public nroParcelas: number = 1,
        public valor: number = 0,
        public dataVencimento: string = '',
    ){}
}

export class MovRegistrarModel {
    constructor(
        public finalizadoraId: string = guidEmpty(),
        public movId: string | null = null,
        public valor: number = 0,
        public numeroTransacao: string = '',
        public pagador: MovRegistrarPagadorModel = new MovRegistrarPagadorModel(),
        public parcelas: MovRegistrarParcelasModel[] = [],
    ) { }
}