import { EnumStatusPedido } from "model/enums/enum-status-pedido";
import { EnumTipoPedido } from "model/enums/enum-tipo-pedido";
import { guidEmpty } from "utils/guid-empty";
import { PedidoClienteModel } from "./pedido-cliente-model";
import { PedidoDadosIntegracaoModel } from "./pedido-integracao-model";
import { EnumPagTpMod } from "model/enums";
import { EnumPedidoPagamentoSituacao } from "model/enums/enum-pedido-pagamento-situacao";

export class PedidoDadosModel {
    constructor(
        public id: string = guidEmpty(),
        public timeStemp: string | Date = '',
        public dataCriacao: string | Date = '',
        public systemUpdateDate: string | Date = '',
        public dataAgendamentoFinal: string | Date = '',
        public dataAgendamentoInicial: string | Date = '',
        public mesaId: string = guidEmpty(),
        public salaoId: string = guidEmpty(),
        public comandaId: string = guidEmpty(),
        public tipoPedido: EnumTipoPedido = EnumTipoPedido.ENTREGA,
        public statusPedido: number = 1,
        public statusAutomacao: number = 1,
        public codigoPedido: string = '',
        public codigoReferencia: string = '',
        public posicaoMesa: string = '',
        public documentoLoja: string = '',
        public quantidadePessoas: number = 0,
        public informacaoAdicional: string = '',
        public aplicarDescontoEcommerce: boolean = true,
        public valorTroco: number = 0,
        public taxaServico: number = 0,
        public valorServico: number = 0,
        public valorEntrega: number = 0,
        public descontoEcommerce: number = 0,
        public cliente: PedidoClienteModel = new PedidoClienteModel(),
        public dadosIntegracao: PedidoDadosIntegracaoModel = new PedidoDadosIntegracaoModel(),
        public identificador: string = ""
    ) { }
}

export class PedidoDadosModelPut {
    constructor(
        public id: string = guidEmpty(),
        public mesaId: string | null = null,
        public mesaCodigo: string = '',
        public salaoId: string | null = null,
        public comandaId: string | null = null,
        public codigoPedido: string | null = null,
        public tipoPedido: EnumTipoPedido = EnumTipoPedido.LOCAL,
        public statusPedido: EnumStatusPedido = EnumStatusPedido.CONFIRMADO,
        public statusAutomacao: number = 1,
        public posicaoMesa: string = '',
        public documentoLoja: string = '',
        public quantidadePessoas: number = 0,
        public informacaoAdicional: string = '',
        public cliente: PedidoClienteModel = new PedidoClienteModel(),
        public dadosIntegracao: PedidoDadosIntegracaoModel = new PedidoDadosIntegracaoModel(),
        public enderecoEntrega: PedidoDadosEnderecoModel = new PedidoDadosEnderecoModel(),
        public pagamentos: PedidoDadosPagamentoModel[] = [],
        public codigoReferencia: string | null = null,

    ) { }
}
export class PedidoDadosModelPost {
    constructor(
        public mesaId: string | null = null,
        public mesaCodigo: string = '',
        public salaoId: string | null = null,
        public comandaId: string | null = null,
        public tipoPedido: EnumTipoPedido = EnumTipoPedido.LOCAL,
        public statusPedido: EnumStatusPedido = EnumStatusPedido.CONFIRMADO,
        public statusAutomacao: number = 1,
        public posicaoMesa: string = '',
        public documentoLoja: string = '',
        public quantidadePessoas: number = 0,
        public informacaoAdicional: string = '',
        public cliente: PedidoClienteModel = new PedidoClienteModel(),
        public dadosIntegracao: PedidoDadosIntegracaoModel = new PedidoDadosIntegracaoModel(),
        public enderecoEntrega: PedidoDadosEnderecoModel = new PedidoDadosEnderecoModel(),
        public pagamentos: PedidoDadosPagamentoModel[] = [],
        public codigoReferencia: string | null = null,
        public identificador: string = ""
    ) { }
}

export class PedidoDadosPagamentoModel {
    constructor(
        public referenceId: string = '',
        public pagamentoDescricao: string = '',
        public valorPago: number = 0,
        public pagamentoPessoa: string = '',
        public tpMod: EnumPagTpMod = EnumPagTpMod.DINHEIRO,
        public transacoes: any[] = [],
        public pagamentoAReceber: boolean = true,
        public valorTroco: number = 0,
        public situacao: EnumPedidoPagamentoSituacao = EnumPedidoPagamentoSituacao.APROVADO
    ) { }
}

export class PedidoDadosEnderecoModel {
    constructor(
        public logradouro: string = '',
        public numero: string = '',
        public bairro: string = '',
        public municipio: string = '',
        public uf: string = '',
        public cep: string = '',
        public complemento: string = '',
        public enderecoCompleto: string = '',
        public pais: string = '',
        public pontoReferencia: string = '',
        public codigoMunicipio: string = '',
    ) { }
}

export class FormIdentificadorModel {
    constructor(
       public identificador: string = '',
       public salaoId: string = '', 
    ){ }
}