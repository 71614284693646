import { useMemo } from 'react';
import * as Yup from 'yup';
export const useFormPagerIdentificadorValidation = () => {
    
    const FormPagerIdentificadorValidationYup = useMemo(() => {
        return (
            Yup.object().shape({
                identificador: Yup.string().required('Identificação do cliente é obrigatório.').typeError('Identificação do cliente é obrigatório'),
                salaoId: Yup.string().required('Identificação do salão é obrigatório.').typeError('Identificação do salão é obrigatório')
            })
        
        )
    },[])
        
            

        

    return {
        FormPagerIdentificadorValidationYup,
    }
}