import { useCallback } from 'react';
import React from 'react';
import { ProdutoResumidoModel } from 'model/api/gestao/produto/produto/produto-resumido-model';
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { isEmpty } from 'lodash';
import { guidEmpty } from 'utils/guid-empty';
import { useCadastros } from 'services/app';
import { useToastSaurus } from 'services/app';
import { useGetCotas } from 'data/api/gestao/cota/get-cota';
import { PropOpcoes } from 'model/api/gestao/plano/plano-model';
import { EnumPlanoOpcoes } from 'model/enums/enum-plano-opcoes';
import { isPlanoFiscal } from 'utils/plano-utils';
import { EnumBalanca } from 'model/enums/enum-balanca';
import { EnumMovModelo } from 'model';
import { ProdutoVincularFiscalModel } from 'model/api/gestao/produto/produto-imposto/produto-vincular-fiscal';
import { usePutProdutoVincularFiscal } from 'data/api/gestao/produto/produto/put-produto-vincular-fiscal';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { newGuid } from 'utils/new-guid';
import { TabelaProdutos } from 'database/interfaces/interface-tabela-produtos';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { useCadastroPadrao } from 'services/app/hooks/cadastro-padrao';
import { consoleDev } from 'utils/console-dev';
import { validaEan } from 'utils/valida-ean';
import { roundTo } from 'utils/round-to';
import { TabelaNCM } from 'database/interfaces/interface-tabela-ncm';
import { calcVTrib } from 'services/app/hooks/utils/calcVTrib';
import { calcPercent } from 'utils/calc-percent';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { toDecimal } from 'utils/to-decimal';
import { EnumTpProduto } from 'model/enums/enum-tp-produto';
import { imagemBase64 } from 'utils/tratamento-de-imagem';
import { CardToastProduto } from 'views/components/cards/card-toast-produto/card-toast-produto';
import { EnumTipoProduto } from 'model/enums/enum-tipo-produto';
import { EnumRoundTo } from 'model/enums/enum-round-to';
import { useContratoAtual } from './contrato-atual';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { useSessaoAtual } from '../providers/sessao-atual';
import { ProdutoCodigoModel } from 'model/api/gestao/produto/produto-codigo/produto-codigo-model';

export enum DialogProdutoEnum {
  DialogProdutoGenerico,
  DialogProdutoFiscal,
  DialogProdutoBalanca,
  DialogProdutoGenericoPesavel
}

export const useMovProd = () => {
  const { inserirProduto, alterarProduto, getMov, getVendedor, removerProdutoComSubItens, aplicaTaxaServicoMov } = useMovAtual();
  const {
    getProdutoAvulso,
    getCategorias,
    getProdutoServico,
    carregando: carregandoCadastroPadrao
  } = useCadastroPadrao();
  const { getConfigByCod: getConfigEmpresaByCod } = useEmpresaAtual()
  const { getConfigByCod: getConfigContratoByCod } = useContratoAtual()
  const { getPessoa, plano } = useSessaoAtual();
  const isFiscal = isPlanoFiscal(plano?.plano);
  const { getCotas, carregando: carregandoCotas } = useGetCotas();
  const { showToast, showToastPersonalizado } = useToastSaurus();
  const {
    abrirAdicionarProduto,
    abrirAdicionarProdutoBalanca,
    abrirAdicionarProdutoFiscais,
    abrirAdicionarProdutoGenerico,
    abrirAdicionarProdutoSemPreco,
    abrirModalLimiteVendaAtingido,
    abrirAdicionarProdutoSubItem,
    abrirModalPropagandaPlano,
    abrirSelecaoProdutosCodigoDialog,
    abrirAdicionarProdutoGenericoPesavel
  } = useCadastros()
  const { putVincularFiscal, carregando: carregandoProdutoVincular } =
    usePutProdutoVincularFiscal();
  const { callEvent } = useEventTools();
  const carregando =
    carregandoCadastroPadrao || carregandoCotas || carregandoProdutoVincular;

  const produtoEmEdicao = React.useRef<MovSimplesProdutoModel | undefined>(
    undefined
  );

  const searchCotas = useCallback(async () => {
    try {
      const res = await getCotas('', 1);
      if (res.erro) throw res.erro;
      return res.resultado?.data;
    } catch (e: any) { }
  }, [getCotas]);

  const alterarProdutoFiscal = useCallback(
    async (
      informacaoFiscal: ProdutoVincularFiscalModel,
      empresaId: string,
      produtoId: string
    ): Promise<void> => {
      const res = await putVincularFiscal(
        informacaoFiscal,
        empresaId,
        produtoId
      );

      if (res.erro) throw res.erro;

      const prod = await TouchoneDBPrimary.produtos
        .where({ produtoId: produtoId })
        .toArray();

      await TouchoneDBPrimary.produtos.update(prod[0].idIndexed!, {
        grupoImpostoId: informacaoFiscal.grupoImpostoId,
        ncmId: informacaoFiscal.ncmId,
        ncm: informacaoFiscal.codncm
      });
    },
    [putVincularFiscal]
  );

  const verifyLimitCotaVendas = useCallback(async () => {
    if (!isFiscal) {
      const res = await searchCotas();

      const total = plano?.plano?.opcoes.find(
        (item) => item.codigo === EnumPlanoOpcoes.Vendas
      );
      if (res?.list && total) {
        const consumed = res.list.find(
          (item: PropOpcoes) => item.codigo === EnumPlanoOpcoes.Vendas
        );
        if (consumed) {
          if (consumed!.quant >= parseInt(total!.valor)) {
            abrirModalLimiteVendaAtingido();
            return false;
          } else if (consumed.quant >= calcPercent(parseInt(total.valor), 95)) {
            abrirModalPropagandaPlano();
          }
        }
      }
    }

    return true;
  }, [abrirModalLimiteVendaAtingido, abrirModalPropagandaPlano, isFiscal, plano?.plano?.opcoes, searchCotas]);

  const inserirProdutoMov = React.useCallback(
    async ({ produto, dialog, isSubItem }: {
      produto: MovSimplesProdutoModel;
      dialog?: DialogProdutoEnum;
      isSubItem?: boolean
    }) => {
      try {
        const mov = getMov();
        const produtoServico = await getProdutoServico()

        const taxaRecomendada = getConfigEmpresaByCod(EnumEmpresaConfig.TaxaServicoRecomendada)
        const taxaRound = roundTo(toDecimal(taxaRecomendada))

        if (mov?.produtos.length === 0) {
          await verifyLimitCotaVendas();
        }

        if (dialog === DialogProdutoEnum.DialogProdutoBalanca || dialog === DialogProdutoEnum.DialogProdutoGenericoPesavel) {
          if (produto.qCom === 0 || produto.qCom === null) {
            produto.qCom = roundTo(produto.vProd / produto.vUnCom, 4, EnumRoundTo.MATEMATICO);
          } else {
            if (roundTo(produto.qCom * produto.vUnCom) !== roundTo(produto.vProd)) {
              produto.vUnCom = roundTo(produto.vProd / produto.qCom, 3, EnumRoundTo.MATEMATICO, true)
            }
          }

          if (roundTo(produto.qCom * produto.vUnCom) !== roundTo(produto.vProd)) {
            produto.qCom = roundTo(produto.vProd / produto.vUnCom, 4, EnumRoundTo.MATEMATICO, true) + 0.0001
          }
        } else {
          produto.vProd = (produto.qComModificador > 0 ? produto.qComModificador : produto.qCom) * produto.vUnCom;
        }
        produto.vFinal = produto.vProd - produto.vDescUsuario + produto.vAcrescUsuario;
        produto.qCom = toDecimal(produto.qCom, 3);
        produto!.xProd = produto.xProd;
        produto.vProd = toDecimal(produto.vProd, 3);
        produto.vFinal = roundTo(produto.vFinal, 3, EnumRoundTo.FINANCEIRO);
        produto.vProd = roundTo(produto.vProd, 3, EnumRoundTo.FINANCEIRO);
        if (produto.cobraTaxaServico && produtoServico && produtoServico.ativo && aplicaTaxaServicoMov()) {
          produto.taxaServico = taxaRound
          produto.valorServico = roundTo(calcPercent(produto.vFinal, taxaRound))
        }

        if (produto.tpProduto === EnumTpProduto.Combo) {
          produto.cobraTaxaServico = false;
        }

        const prodAvulso = await getProdutoAvulso();
        //VALIDAR SE O PRODUTO TEM NCM E IMPOSTO PARA VENDA NFCe
        if (!(dialog === DialogProdutoEnum.DialogProdutoFiscal) && (mov?.mod === EnumMovModelo.NFCE || mov?.mod === EnumMovModelo.NFE)) {
          if (
            isEmpty(produto.ncm) ||
            isEmpty(produto.ncmId) ||
            isEmpty(produto.grupoImpostoId)
          ) {
            produtoEmEdicao.current = produto
            abrirAdicionarProdutoFiscais(produto);
            return;
          }
        }

        //VALIDAR ENTRADA DO PRODUTO COM SUBITEM
        if (produto.subItens.length > 0 && !produto.validacaoSubItem) {
          produtoEmEdicao.current = produto
          abrirAdicionarProdutoSubItem(produto, false);
          return;
        }

        //VALIDAR ENTRADA DO PRODUTO GENÉRICO
        if (!(dialog === DialogProdutoEnum.DialogProdutoGenerico) && produto.produtoGradeId === prodAvulso?.produtoGradeId) {
          if (dialog === DialogProdutoEnum.DialogProdutoGenericoPesavel) {
            inserirProduto(produto)
            return;
          } else if (produto.balanca === EnumBalanca.PesadoVenda || produto.balanca === EnumBalanca.Pesavel || produto.balanca === EnumBalanca.Glaciado) {
            abrirAdicionarProdutoGenericoPesavel(produto)
            return
          } else {
            produtoEmEdicao.current = produto
            abrirAdicionarProdutoGenerico(produto);
            return;
          }
        }
        //SE O PRODUTO ESTIVER SEM PREÇO
        else if (
          produto.vUnCom === 0 &&
          produto.produtoGradeId !== prodAvulso?.produtoGradeId && !isSubItem
        ) {
          produtoEmEdicao.current = produto
          abrirAdicionarProdutoSemPreco(produto);
          return;
        } else if (
          !(dialog === DialogProdutoEnum.DialogProdutoBalanca) &&
          ((produto.balanca === EnumBalanca.PesadoVenda ||
            produto.balanca === EnumBalanca.Glaciado ||
            produto.balanca === EnumBalanca.Pesavel) && !isSubItem)
        ) {
          produtoEmEdicao.current = produto
          abrirAdicionarProdutoBalanca(produto);
          return;
        }

        const prod = await inserirProduto(produto);
        produtoEmEdicao.current = undefined;

        return prod
      } catch (e: any) {
        showToast('error', `Erro ao inserir o produto. Detalhe: ${e.message}`);
        return;
      }
    },
    [getMov, getProdutoServico, getConfigEmpresaByCod, aplicaTaxaServicoMov, getProdutoAvulso, inserirProduto, verifyLimitCotaVendas, abrirAdicionarProdutoFiscais, abrirAdicionarProdutoSubItem, abrirAdicionarProdutoGenericoPesavel, abrirAdicionarProdutoGenerico, abrirAdicionarProdutoSemPreco, abrirAdicionarProdutoBalanca, showToast]
  );

  const retornaModelMovSimples = React.useCallback(
    async (
      produto: ProdutoResumidoModel,
      cProdInsercao: string = ''
    ): Promise<MovSimplesProdutoModel> => {
      const mov = getMov();
      if (isEmpty(mov) || !mov) {
        throw new Error('Não existe uma venda em Andamento.');
      }

      let categoriadesc = '';
      const categorias = await getCategorias();
      if (categorias) {
        const cat = categorias.find((x) => x.id === produto.categoriaId);
        if (cat) {
          categoriadesc = cat.descricao || '';
        }
      }

      const vendedor = await getVendedor();

      let ean = produto.codigos.filter((x) => validaEan(x.codigo));
      let codInsercao = produto.codigos.filter(
        (x) => x.codigo === cProdInsercao
      );
      let isKit = false;

      //VALIDACAO PARA SABER SE É KIT
      if (codInsercao.length > 0) {
        isKit =
          (codInsercao[0].fator || 0) > 0 ||
          (codInsercao[0].percTabela || 0) > 0 ||
          (codInsercao[0].precoFixo || 0) > 0;
      }

      // DADOS DE TRIBUTACAO
      let NCMIndex: TabelaNCM | undefined = undefined;

      if (produto.ncm) {
        // NCM DO PRODUTO
        NCMIndex = await TouchoneDBPrimary.ncms.get({
          codigo: produto.ncm
        });
      }

      let prod = new MovSimplesProdutoModel(
        0,
        produto.produtoGradeId,
        mov.cliente?.tabelaPrecoId,
        vendedor.id === guidEmpty() ? getPessoa()?.pessoa?.id : vendedor.id,
        vendedor.id === guidEmpty() ? getPessoa()?.pessoa?.nome : vendedor.nome,
        produto.codigo,
        isKit ? cProdInsercao : '',
        ean.length > 0 ? ean[0].codigo : '',
        produto.nome,
        true,
        produto.medida,
        0,
        produto.vPreco,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        imagemBase64(produto.imagemUrl),
        categoriadesc,
        '',
        produto.ncm,
        produto.temImposto,
        false,
        produto.balanca || 0,
        produto.grupoImpostoId,
        produto.ncmId,
        calcVTrib(
          NCMIndex?.pTribManual ?? 0,
          NCMIndex?.pTribFederal ?? 0,
          NCMIndex?.pTribEstadual ?? 0,
          NCMIndex?.pTribMunicipal ?? 0,
          produto.vPreco
        ),
        NCMIndex?.pTribManual ?? 0,
        NCMIndex?.pTribFederal ?? 0,
        NCMIndex?.pTribEstadual ?? 0,
        NCMIndex?.pTribMunicipal ?? 0,
        produto.produtoId,
        produto.id,
        '',
        '',
        '',
        produto.setorId,
        '',
        null,
        null,
        produto.cobraTaxaServico,
        produto.subItens,
      );

      prod.cProdANVISA = produto.codigoAnvisa

      return prod
    },
    [getCategorias, getMov, getPessoa, getVendedor]
  );

  const insertCentralizadoProduto = React.useCallback(
    async (
      produto: ProdutoResumidoModel,
      cProdInsercao: string,
      qCom: number,
      vUnCom: number,
      vProd: number,
      vDesc: number,
      vAcresc: number,
      infAdic: string
    ) => {
      try {
        
        const mov = getMov();
        if (isEmpty(mov) || !mov) {
          showToast('error', 'Não existe uma venda em Andamento.');
          return;
        }

        // if (mov.produtos.length === 0) {
        //   const verify = await verifyLimitCotaVendas();
        //   if (!verify) {
        //     return;
        //   }
        // }

        const model = await retornaModelMovSimples(produto, cProdInsercao);
        model.id = newGuid();
        model.qCom = qCom;
        model.vUnCom = vUnCom !== 0 ? vUnCom : model.vUnCom;
        model.vProd = vUnCom !== 0 ? vProd : model.vUnCom;
        model.vDescUsuario = vDesc;
        model.vAcrescUsuario = vAcresc;
        model.infAdic = infAdic;
        model.tpProduto = (produto.tipo as unknown as EnumTpProduto);
        model.cProdANVISA = produto.codigoAnvisa;
        model.identificador = mov.informacoesGeraisPedido.identificador ?? ''

        //Se foi passado um código de balança não precisa mostrar o dialog de pesagem, verifico isso aqui
        const mostrarBalanca = cProdInsercao[0] === '2' && cProdInsercao.length === 13

        return await inserirProdutoMov({ produto: model, dialog: mostrarBalanca ? DialogProdutoEnum.DialogProdutoBalanca : undefined });
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [
      getMov,
      inserirProdutoMov,
      retornaModelMovSimples,
      showToast,
    ]
  );

  const inserirProdutoFromSearchMedicamento = React.useCallback(
    async (codigoAnvisa): Promise<void> => {

      const produto = await TouchoneDBPrimary.produtos.where('codigoAnvisa').equals(codigoAnvisa).first()

      if (!produto) {
        throw new Error('Produto não encontado.')
      }

      const prod = await insertCentralizadoProduto(
        produto as ProdutoResumidoModel,
        '',
        1,
        produto.vPreco,
        0,
        0,
        0,
        ''
      );

      if (prod) {
        showToast('success', 'Produto adicionado')
      }
    },
    [insertCentralizadoProduto, showToast]
  );

  const inserirProdutoFromSearch = React.useCallback(
    async (produto: ProdutoResumidoModel): Promise<void> => {
      await insertCentralizadoProduto(
        produto,
        '',
        1,
        produto.vPreco,
        0,
        0,
        0,
        ''
      );
    },
    [insertCentralizadoProduto]
  );

  const inserirProdutoFromSearchDetailed = React.useCallback(
    async (produto: ProdutoResumidoModel, codigo?: ProdutoCodigoModel | null): Promise<void> => {
      const avulso = await getProdutoAvulso();

      if (produto.codigos.length > 1 && !codigo) {
        abrirSelecaoProdutosCodigoDialog(produto, true)
        return;
      }
      if (
        produto.produtoGradeId === avulso?.produtoGradeId ||
        produto.balanca === EnumBalanca.Glaciado ||
        produto.balanca === EnumBalanca.PesadoVenda ||
        produto.balanca === EnumBalanca.Pesavel ||
        produto.vPreco === 0 ||
        produto.subItens.length > 0
      ) {
        inserirProdutoFromSearch(produto);
        return;
      }
      const model = await retornaModelMovSimples(produto);
      if (model.subItens.length === 0) {
        abrirAdicionarProduto(model, codigo);
      }
    },
    [abrirAdicionarProduto, abrirSelecaoProdutosCodigoDialog, getProdutoAvulso, inserirProdutoFromSearch, retornaModelMovSimples]
  );

  const inserirProdutoByCodigo = React.useCallback(
    async (codigo: string): Promise<TabelaProdutos> => {
      const calculateCheckDigit = function (eanCode: string): number {
        const digits = eanCode.split('').map(Number);
        let evenSum = 0;
        let oddSum = 0;

        for (let i = 0; i < digits.length; i++) {
          if ((i + 1) % 2 === 0) {
            evenSum += digits[i];
          } else {
            oddSum += digits[i];
          }
        }

        const totalSum = evenSum * 3 + oddSum;
        const remainder = totalSum % 10;
        const checkDigit = remainder === 0 ? 0 : 10 - remainder;

        return checkDigit;
      }
      const codigoValidar = codigo.includes('*') ? codigo.split('*')[1] : codigo

      const checkDigit = calculateCheckDigit(codigoValidar.substring(0, codigoValidar.length - 1))

      if (codigo[codigo.length - 1] !== checkDigit.toString() && codigoValidar.length === 13 && codigoValidar.substr(0, 1) === '2') {
        throw new Error(`O código '"${codigo}"' é inválido.`)
      }

      const mov = getMov();
      if (isEmpty(mov) || !mov) {
        throw new Error('Não existe uma venda em Andamento.');
      }

      let qtde = 1;
      let valorTotal = 0;
      let codFinal = codigoValidar;
      let valorUn = 0;
      //ETIQUETA DE BALANÇA
      if (codigoValidar.length === 13 && codigoValidar.substr(0, 1) === '2') {
        const tpFinalCodigo = Number(getConfigContratoByCod(EnumContratoConfig.TipoFinalCodigo));
        const qtdeCodigo = Number(getConfigContratoByCod(EnumContratoConfig.TamanhoCodigoProduto))
        codFinal = codigoValidar.substr(1, qtdeCodigo);
        const finalCod = codigoValidar.substring(qtdeCodigo + 2, codigoValidar.length - 1);
        if (tpFinalCodigo === 0) {
          valorTotal = parseFloat(finalCod) / 100;
        } else {
          //TRANSFORMO O CODIGO EM STRING
          const finalCodStr = finalCod.toString()

          let indexInicial = 0
          //BUSCO PELOS 4 ULTIMOS DIGITOS Q DEFINEM O VALOR OU PESO
          for (let i = 0; i <= finalCodStr.length; i++) {
            if (finalCodStr[i] !== '0') {
              indexInicial = i
              break
            }
          }
          const pesoStr = finalCodStr.substring(indexInicial)
          const threshold = pesoStr.length === 2 ? pesoStr.length : pesoStr.length === 3 ? pesoStr.length : pesoStr.length === 4 ? 1 : pesoStr.length === 5 ? 2 : pesoStr.length === 6 ? 3 : 4
          //TRANSFORMO O CÓDIGO EM MEDIDA DE PESO DECIMAL
          if (pesoStr.length <= 3) {
            qtde = Number(`0.${pesoStr}`)
          } else {
            const peso = finalCodStr.substring(indexInicial, indexInicial + threshold) + '.' + finalCodStr.substring(indexInicial + threshold, indexInicial + threshold + 3)
            qtde = toDecimal(peso, 3);
          }
        }
      }
      //PRODUTO COM MULTIPLICACAO
      if (codigo.indexOf('*') > -1) {
        const qtdeCodigo = codigo
          .substr(0, codigo.indexOf('*'))
          .replace(',', '.');

        if (isNaN(parseFloat(qtdeCodigo))) {
          throw new Error('A Quantidade informada não é Válida.');
        }

        qtde = qtde > 1 ? (qtde * parseFloat(qtdeCodigo)) : parseFloat(qtdeCodigo);
      }

      function compareCodes(code1: string, code2: string) {
        // Remove leading zeros and compare the remaining strings
        const trimmedCode1 = code1.replace(/^0+/, '');
        const trimmedCode2 = code2.replace(/^0+/, '');

        return trimmedCode1 === trimmedCode2;
      }

      const produtos = await TouchoneDBPrimary.produtos.toArray();

      const produtoCodigo = produtos.filter(
        (item) => {
          const foundCode = item.codigos?.filter((i) => compareCodes(i.codigo, codFinal))[0]?.codigo
          return foundCode && compareCodes(foundCode, codFinal)
        }
      );

      if (produtoCodigo.length === 0) {
        throw new Error(`O código '"${codFinal}"' não foi localizado.`);
      }

      if (produtoCodigo[0].tipo === EnumTipoProduto.Insumo) {
        throw new Error('Não é possível adicionar insumos ao carrinho.')
      }

      if ((codigoValidar.length === 13 && codigoValidar.substr(0, 1) === '2') && (
        produtoCodigo[0].balanca !== EnumBalanca.Pesavel &&
        produtoCodigo[0].balanca !== EnumBalanca.PesadoVenda
      )) {
        throw new Error('O Produto informado no código não é pesável.')
      }

      if (produtoCodigo[0].codigos?.length! > 1) {
        produtoCodigo[0].codigo = codFinal;
      }

      const produtoCodigoFinal = produtoCodigo[0].codigos?.find(
        (cod) => cod.codigo === codFinal
      );

      if (produtoCodigoFinal?.precoFixo) {
        valorUn = produtoCodigoFinal.precoFixo / produtoCodigoFinal.fator;
      } else if (produtoCodigoFinal?.percTabela) {
        const porcentagem = produtoCodigoFinal.percTabela / 100;
        const porcCalc = produtoCodigo[0].vPreco * porcentagem;

        valorUn = produtoCodigo[0].vPreco + porcCalc;
      }

      if (codigoValidar.length === 13 && codigoValidar.substr(0, 1) === '2') {
        //SE O VALOR TOTAL FOR + Q 0 SIGNIFICA Q O TIPO DE BALANÇA É VALOR TOTAL
        valorUn = produtoCodigo[0].vPreco
        if (valorTotal > 0) {
          qtde = 0
        } else {
          valorTotal = qtde * valorUn
        }
      }
      qtde = produtoCodigoFinal?.fator ? produtoCodigoFinal?.fator * qtde : qtde;
      const prod = await insertCentralizadoProduto(
        produtoCodigo[0] as ProdutoResumidoModel,
        codigoValidar,
        qtde,
        valorUn,
        valorTotal,
        0,
        0,
        ''
      );

      if (qtde > 1 && prod) {
        showToastPersonalizado('none', <CardToastProduto model={prod} />)
      }

      return produtoCodigo[0];
    },
    [getConfigContratoByCod, getMov, insertCentralizadoProduto, showToastPersonalizado]
  );

  const inserirProdutoAvulso = React.useCallback(
    async (vProd: number): Promise<boolean> => {
      try {
        const taxaRecomendada = getConfigEmpresaByCod(EnumEmpresaConfig.TaxaServicoRecomendada)
        const taxa = roundTo(toDecimal(taxaRecomendada))


        // if (getMov()?.produtos.length === 0) {
        //   const verify = await verifyLimitCotaVendas();
        //   if (!verify) {
        //     return false;
        //   }
        // }

        const pAvulso = await getProdutoAvulso();
        if (!pAvulso) {
          throw new Error('Produto avulso não Identificado');
        }

        pAvulso.vPreco = vProd;
        pAvulso.codigos = [...pAvulso.codigos];

        const model = await retornaModelMovSimples(pAvulso);

        const valorTaxa = roundTo(calcPercent(model.vUnCom, taxa))

        model.qCom = 1;
        model.vProd = model.vUnCom;
        model.vFinal = model.vUnCom;
        model.vDescUsuario = 0;
        model.vAcrescUsuario = 0;
        model.infAdic = '';
        if (pAvulso.cobraTaxaServico && aplicaTaxaServicoMov()) {
          model.taxaServico = taxa
          model.valorServico = valorTaxa
        }
        await inserirProduto(model);
        return true;
      } catch (e: any) {
        showToast('error', e.message);
        return false;
      }
    },
    [aplicaTaxaServicoMov, getConfigEmpresaByCod, getProdutoAvulso, inserirProduto, retornaModelMovSimples, showToast]
  );

  const alterarProdutoAvulso = React.useCallback(
    async (price: number): Promise<boolean> => {
      try {
        const mov = getMov();
        if (isEmpty(mov) || !mov) {
          throw new Error('Não existe uma venda em Andamento.');
        }

        const pAvulso = await getProdutoAvulso();
        if (!pAvulso) {
          throw new Error('Produto avulso não Identificado');
        }

        const avulsos = mov.produtos.filter(
          (x) =>
            x.produtoGradeId === pAvulso?.produtoGradeId && x.ativo === true
        );
        if (avulsos.length === 0) {
          throw new Error('Produto avulso não Identificado');
        }

        if (price === 0) {
          avulsos[avulsos.length - 1].ativo = false;
          await alterarProduto(avulsos[avulsos.length - 1]);
        } else {
          //ATUALIZAR VALOR
          avulsos[avulsos.length - 1].vFinal = price;
          avulsos[avulsos.length - 1].vProd = price;
          avulsos[avulsos.length - 1].vUnCom = price;
          await alterarProduto(avulsos[avulsos.length - 1]);
        }
        return true;
      } catch (e: any) {
        showToast('error', e.message);
        return false;
      }
    },
    [alterarProduto, getMov, getProdutoAvulso, showToast]
  );

  const alterarDadosFiscaisProduto = React.useCallback(
    async (
      informacaoFiscal: ProdutoVincularFiscalModel,
      empresaId: string,
      produtoId: string,
      produto: MovSimplesProdutoModel
    ) => {
      try {
        if (!produto) return;

        await alterarProdutoFiscal(informacaoFiscal, empresaId, produtoId);

        produto.grupoImpostoId =
          informacaoFiscal.grupoImpostoId;
        produto.ncmId = informacaoFiscal.ncmId;
        produto.ncm = informacaoFiscal.codncm;

        callEvent(AppEventEnum.ProdutoAlterado, produto);

        showToast('success', 'Informações vinculadas');
        return await inserirProdutoMov({ produto });
      } catch (e: any) {
        showToast(
          'error',
          `Erro ao vincular os tributos ao produto. Tente novamente. Detalhe: ${e.message}`
        );
      }
    },
    [alterarProdutoFiscal, callEvent, inserirProdutoMov, showToast]
  );
  consoleDev('MovProdHook');

  const retornDescUsuario = useCallback((produto: MovSimplesProdutoModel) => {
    return produto.qCom === 0 ? 0 : produto.vFinal < produto.vDescUsuario ? 0 : produto.vDescUsuario
  }, []);

  const retornVFinal = useCallback((produto: MovSimplesProdutoModel) => {
    return produto.vFinal < 0 ? 0 : produto.vFinal
  }, []);

  const retornQCom = useCallback((produto: MovSimplesProdutoModel) => {
    return produto.tpProduto === EnumTpProduto.ProdutoComSubItem || produto.tpProduto === EnumTpProduto.Combo
      ? produto.qCom
      : produto.qCom > produto.infoSubItem!.qPadrao && !produto.idAdicional
        ? produto.infoSubItem!.qPadrao
        : produto.qCom
  }, []);

  const retornGroupId = useCallback((produto: MovSimplesProdutoModel, produtoPaiId: string) => {
    return produto.idGroup === null ? null : produtoPaiId
  }, [])

  const retornIndFin = useCallback((produto: MovSimplesProdutoModel, indFin: boolean) => {
    return indFin ? indFin : produto.produtoPaiId ? false : true;
  }, [])

  const adicionaProdutoComSubitens = useCallback(
    async (
      produto: MovSimplesProdutoModel,
      indFin: boolean = false,
      produtoPaiId: string,
      adicionaisGerais: MovSimplesProdutoModel[],
      tpProduto?: EnumTpProduto | EnumTipoProduto
    ) => {
      const { prodSubItem } = produto;

      const product = {
        ...produto,
        vDescUsuario: retornDescUsuario(produto),
        vFinal: retornVFinal(produto),
        validacaoSubItem: true,
        indFin: retornIndFin(produto, indFin),
        idGroup: retornGroupId(produto, produtoPaiId),
        qCom: retornQCom(produto),
      };

      if ((product.tpProduto === EnumTpProduto.Adicional && product.qCom <= 0) || (product.tpProduto === EnumTpProduto.Opcional && product.qCom <= 0)) {
        return
      }

      const prod = await inserirProdutoMov({ produto: { ...product, indFin: tpProduto === EnumTpProduto.Combo && product.qCom > 0 ? true : product.vUnCom === 0 ? false : retornIndFin(produto, indFin) }, isSubItem: true });

      if (prod && prod.qCom > 0 && prodSubItem.length > 0) {
        for (let i of prodSubItem) {
          const p = {
            ...i,
            idDoProdutoPaiInfoSubItem: produto.id,
            qCom: produto.qCom === 0 ? 0 : i.qCom,
            vFinal: produto.qCom === 0 ? 0 : i.vFinal,
            vDescUsuario: produto.qCom === 0 ? 0 : i.vDescUsuario,
          };

          await adicionaProdutoComSubitens(p, false, produtoPaiId, adicionaisGerais, prod.tpProduto);
        }
      }

      return prod;
    },
    [inserirProdutoMov, retornDescUsuario, retornGroupId, retornIndFin, retornQCom, retornVFinal]
  );

  const adicionarProduto = useCallback(async (produto: MovSimplesProdutoModel) => {
    return await inserirProdutoMov({ produto: { ...produto, id: newGuid() } });
  }, [inserirProdutoMov])

  const adicionarProdutoGenerico = useCallback(
    async (produto: MovSimplesProdutoModel | undefined, vUnCom: number, qCom: number, xProd: string) => {
      if (!produto) return;
      produto.vUnCom = vUnCom;
      produto.qCom = qCom;
      produto.xProd = xProd;
      return await inserirProdutoMov({
        produto: produto,
        dialog: DialogProdutoEnum.DialogProdutoGenerico
      });
    }, [inserirProdutoMov]
  )

  const adicionarProdutoSemPreco = useCallback(
    async (produto: MovSimplesProdutoModel | undefined, vUnCom: number, qCom: number) => {
      if (!produto) return;

      produto.vUnCom = vUnCom;
      produto.qCom = qCom;
      return await inserirProdutoMov({ produto });
    },
    [inserirProdutoMov]
  )

  const adicionarProdutoBalanca = useCallback(
    async (produto: MovSimplesProdutoModel | undefined) => {
      if (!produto) return;
      return await inserirProdutoMov({ produto, dialog: DialogProdutoEnum.DialogProdutoBalanca });
    }, [inserirProdutoMov]
  )

  const adicionarProdutoGenericoPesavel = useCallback(
    async (produto: MovSimplesProdutoModel | undefined,
    ) => {

      if (!produto) return;


      return await inserirProdutoMov({
        produto: produto,
        dialog: DialogProdutoEnum.DialogProdutoGenericoPesavel
      });
    }, [inserirProdutoMov]
  )

  const adicionarProdutoComSubItem = useCallback(async (
    produto: MovSimplesProdutoModel,
    adicionais: Array<MovSimplesProdutoModel>,
    valorFinal: number
  ) => {
    for (let i = 1; i <= produto.qCom; i++) {
      const newId = newGuid();

      const newAdicionais = [];
      const tpProdutoPai = (produto.tpProduto as unknown as EnumTipoProduto) === EnumTipoProduto.Produto ? EnumTpProduto.ProdutoComSubItem : EnumTpProduto.Combo

      for (const add of adicionais) {
        const prod = {
          ...add,
          validacaoSubItem: true,
          qCom: add.qCom,
          idGroup: newId,
          vDescUsuario: tpProdutoPai === EnumTpProduto.Combo ? add.vDescUsuario : 0,
          tpProduto: EnumTpProduto.Adicional
        };

        newAdicionais.push(prod);
        await adicionaProdutoComSubitens(prod, true, newId, newAdicionais);
      }
      const prod = { ...produto, adicionais: newAdicionais, qCom: 1, id: newId, tpProduto: tpProdutoPai }

      await adicionaProdutoComSubitens(
        prod,
        false,
        newId,
        newAdicionais,
        prod.tpProduto
      );
    }
  }, [adicionaProdutoComSubitens])

  const editarProdutoComSubItem = useCallback(async (
    produto: MovSimplesProdutoModel,
    adicionais: MovSimplesProdutoModel[],
    valorFinal: number
  ) => {
    // remove todos os produtos relacionados e depois adicionar o modificado
    await removerProdutoComSubItens(produto);

    for (let i = 1; i <= produto.qCom; i++) {
      for (let j of adicionais) {
        await adicionaProdutoComSubitens({ ...j, validacaoSubItem: true, vDescUsuario: 0, qCom: j.qCom, idGroup: produto.id }, true, produto.id, adicionais);
      }
      return await adicionaProdutoComSubitens({ ...produto, adicionais: adicionais, qCom: 1, id: produto.id }, false, produto.id, adicionais);
    }
  }, [adicionaProdutoComSubitens, removerProdutoComSubItens])

  return {
    inserirProdutoAvulso,
    alterarProdutoAvulso,

    inserirProdutoByCodigo,
    inserirProdutoFromSearchDetailed,
    inserirProdutoFromSearchMedicamento,
    inserirProdutoFromSearch,
    carregando,
    retornaModelMovSimples,
    adicionaProdutoComSubitens,

    // FUNÇÕES DOS MODAIS
    adicionarProduto,
    adicionarProdutoGenerico,
    adicionarProdutoSemPreco,
    adicionarProdutoBalanca,
    alterarDadosFiscaisProduto,
    adicionarProdutoComSubItem,
    editarProdutoComSubItem,
    adicionarProdutoGenericoPesavel
  }
};