import classNames from "classnames"
import { ButtonModalHeader } from "views/components/controles/buttons/button-modal-header"
import { OkIcon, VoltarIcon } from "views/components/icons"
import { ModalHeader } from "views/components/modals/components"
import { useModalStyles } from "views/components/modals/utils/modal-styles"
import { Box, Button, Checkbox, Grid, Typography } from "@material-ui/core"
import { LoadingFinalizacao } from "views/components/utils"
import { useEmpresaAtual } from "services/app/hooks/empresa-atual"
import { useCallback, useState } from "react"
import ArquivoInput from "views/components/controles/inputs/arquivo-input/arquivo-input"
import { EnumTipoRetornoArquivo } from "views/components/controles/inputs/arquivo-input"
import { useCadastros, useToastSaurus } from "services/app"
import { usePostNovaEntradaXML } from "data/api/gestao/venda/post-nova-entrada-xml"
import { useHistory } from "react-router-dom"
import { isEmpty } from "lodash"
import { CadastroEntradaManualProps } from "../cadastro-entrada-manual/cadastro-entrada-manual-props"
import { useStyles } from '../entrada-opcoes/entrada-opcoes-styles'
import { validaGuid } from "utils/valida-guid"
import { useConfirmSaurus } from "services/app/hooks/confirm-saurus"

export const CadastroXML = ({ voltarEntrada }: CadastroEntradaManualProps) => {

    const modalClasses = useModalStyles()
    const { getEmpresaAtual } = useEmpresaAtual();
    const { fecharEntradaModal } = useCadastros();
    const { showToast } = useToastSaurus();
    const { showConfirm } = useConfirmSaurus();
    const { postNovaEntradaXML, carregando } = usePostNovaEntradaXML();
    const { push } = useHistory();
    const classes = useStyles();

    const [arquivo, setArquivo] = useState<string>('');
    const [refazer, setRefazer] = useState<boolean>(true);

    const onSubmit = useCallback(
        async () => {
            if (isEmpty(arquivo)) {
                showToast('info', 'Importa uma nota de entrada para continuar.');
                return
            }
            const id = getEmpresaAtual()?.id ?? '';

            try {
                const response = await postNovaEntradaXML({
                    xml: btoa(arquivo),
                    id,
                    refazer
                });
                if (response.erro) {
                    if (validaGuid(response.erro.message) && response.statusCode === 410) {
                        showConfirm({
                            title: 'Nota já importada!',
                            description: 'Detectamos que essa nota já foi importada anteriormente. Deseja ser redirecionado a página da entrada?',
                            primaryButtonText: 'Redirecionar',
                            secondaryButtonText: 'Fechar',
                        }).then(() => {
                            push(`/entrada-mercadoria/visualizar/${response.erro.message}`);
                            fecharEntradaModal();
                        })
                        return
                    }
                    throw response.erro;
                }

                push(
                    `/entradas/visualizar/${response.resultado?.data.id}/importacao-xml`
                );
                fecharEntradaModal();
            } catch (error: any) {
                showToast('error', error.message);
                fecharEntradaModal();
            }
        },
        [arquivo, getEmpresaAtual, showToast, postNovaEntradaXML, refazer, push, fecharEntradaModal, showConfirm]
    );

    return (
        <div className={modalClasses.root}>
            <ModalHeader
                title={'Importar XML'}
                leftArea={
                    <ButtonModalHeader
                        tooltip="Voltar"
                        icon={<VoltarIcon tipo="MODAL_HEADER" />}
                        onClick={voltarEntrada}
                    />
                }
            />
            <div className={modalClasses.content}>
                {carregando ? (
                    <Grid className={classes.content}>
                        <Grid className={classes.infoContainer}>
                            <Grid className={classes.loadingContent}>
                                <LoadingFinalizacao />
                            </Grid>
                        </Grid>
                    </Grid>
                ) : <>
                    <div className={classNames(modalClasses.contentForms)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} style={{ marginBottom: '10px' }}>
                                <Typography variant="caption">
                                    Arquivo de Entrada(.xml)
                                </Typography>
                                <ArquivoInput
                                    tipoRetorno={EnumTipoRetornoArquivo.String}
                                    value={arquivo}
                                    onChange={({ base64 }: any) => {
                                        setArquivo(base64)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box display='flex' gridGap={2} alignItems='center' onClick={() => {
                                    setRefazer(prev => !prev)
                                }}>
                                    <Checkbox checked={refazer} />
                                    <Typography>
                                        Carregar dados preenchidos anteriormente, se possível.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={modalClasses.acoes}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    disabled={carregando}
                                    onClick={() => {
                                        onSubmit();
                                    }}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                >
                                    <OkIcon tipo="BUTTON_PRIMARY" />
                                    Confirmar
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </>}

            </div>
        </div >
    )
}