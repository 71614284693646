import { useGetFinalizadoras } from 'data/api/gestao/finalizadora';
import { useGetMovPagamentoById } from 'data/api/gestao/mov-pag/get-mov-pagamento-by-id';
import { usePostMovRegistrar } from 'data/api/gestao/mov-pag/post-registrar';
import { MovRegistrarModel, MovRegistrarPagadorDadosModel, MovRegistrarParcelasModel } from 'model/api/gestao/mov-pag/mov-registrar';
import { MovRegistrarResponseModel } from 'model/api/gestao/mov-pag/mov-registrar-response';
import { MovSimplesPagamentoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-pagamento-model';
import { StatusPagamento } from 'model/enums/enum-status-pagamento';
import { EnumStatusPix } from 'model/enums/enum-status-pix';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useCadastros, useToastSaurus } from 'services/app';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { guidEmpty } from 'utils/guid-empty';
import { stringNumeros } from 'utils/string-numeros';
import { DialogSaurus } from 'views/components/dialog/dialog-saurus/dialog-saurus';
import { PagamentoPixDetalhes } from './components/pagamento-pix-detalhes/pagamento-pix-detalhes';
import { CircularLoading } from 'views/components/utils';
import { Box, Button, LinearProgress, Typography } from '@material-ui/core';
import { useThemeQueries } from 'views/theme';
import { useConfirm } from 'material-ui-confirm';
import { useStyles } from './components/pagamento-pix-detalhes/pagamento-pix-detalhes-styles'
import { CancelarIcon } from 'views/components/icons';

export interface DialogPagarPixProps {
    pagamento: MovSimplesPagamentoModel;
    aberto: boolean;
    finalizar: () => Promise<any>;
    cancelar: () => void;
}

export const DialogPagarPix = ({
    aberto,
    pagamento,
    cancelar,
    finalizar,
    ...props
}: DialogPagarPixProps) => {

    const { getMovPagamentoById } = useGetMovPagamentoById();
    const { getFinalizadoras, carregando: carregandoGetFinalizadoras } = useGetFinalizadoras();
    const { postMovRegistrar, carregando: carregandoPost } = usePostMovRegistrar();
    const carregando = carregandoPost || carregandoGetFinalizadoras

    const { getMov } = useMovAtual();
    const { getEmpresaAtual } = useEmpresaAtual();
    const { showToast } = useToastSaurus();
    const { fecharDialogPix } = useCadastros();
    const { isMobile } = useThemeQueries();
    const confirm = useConfirm();

    const [dadosPix, setDadosPix] = useState<MovRegistrarResponseModel | null>(null)
    const idInterval = useRef<NodeJS.Timeout>()
    const [statusPix, setStatusPix] = useState<EnumStatusPix>(EnumStatusPix.Neutro)
    const [pixErro, setPixErro] = useState<boolean>(false)
    const [progress, setProgress] = useState<number>(0)

    const classes = useStyles()

    const handleRegistroPix = useCallback(async (): Promise<void> => {
        try {
            const registrar: MovRegistrarModel = new MovRegistrarModel()
            const query = "?TpTransacao=11"


            const resFinalizadora = await getFinalizadoras(query)
            if (resFinalizadora.erro) throw resFinalizadora.erro

            registrar.finalizadoraId = pagamento.pagamentoId ?? guidEmpty()
            registrar.valor = pagamento.vPag

            const cliente = getMov()?.cliente;

            registrar.pagador = {
                pessoaId: cliente?.id || '',
                dadosPessoa: {
                    ...new MovRegistrarPagadorDadosModel(),
                    cpfCnpj: cliente?.cpfcnpj ?? '',
                    fone: stringNumeros(cliente?.contatos.find(x => x.tipo === 0)?.valor ?? ''),
                    email: cliente?.contatos.find(x => x.tipo === 1)?.valor ?? '',
                    nome: cliente?.nome ?? 'Consumidor'
                }
            }

            registrar.parcelas = [
                new MovRegistrarParcelasModel(1, pagamento.vPag, '')
            ]

            const res = await postMovRegistrar(getEmpresaAtual()!.id, registrar)
            if (res.erro) throw res.erro

            setPixErro(false)
            setDadosPix(res.resultado?.data);
        } catch (e: any) {
            setPixErro(true)
            showToast('error', e.message)
        }
    }, [getEmpresaAtual, getFinalizadoras, getMov, pagamento.pagamentoId, pagamento.vPag, postMovRegistrar, showToast])

    const verificarPagamento = useCallback(async () => {
        const data = new Date(dadosPix?.dataRegistro ?? new Date())
        const expiracao = new Date(data.setMinutes(data.getMinutes() + 30))
        try {
            if (aberto) {
                const res = await getMovPagamentoById(dadosPix?.id || '', getEmpresaAtual()!.id)
                if (res.erro) throw res.erro

                const data = res.resultado?.data

                if (data === StatusPagamento.Pago) {
                    clearInterval(idInterval.current)
                    setStatusPix(EnumStatusPix.Aceito)
                    await finalizar();

                    fecharDialogPix();
                    showToast('success', 'Pedido enviado com sucesso!')
                    return
                } else if (new Date().getTime() >= expiracao.getTime()) {
                    setStatusPix(EnumStatusPix.Cancelado)
                    clearInterval(idInterval.current)
                    return
                }
            }
        } catch (err: any) {
            clearInterval(idInterval.current)
            setPixErro(true)
            showToast('error', err.message)
        }
    }, [aberto, dadosPix, finalizar, getEmpresaAtual, getMovPagamentoById, fecharDialogPix, showToast])

    const handleCancelar = useCallback(() => {
        clearInterval(idInterval.current)
        cancelar();
        fecharDialogPix()
    }, [cancelar, fecharDialogPix])

    useEffect(() => {
        handleRegistroPix();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (dadosPix && aberto) {
            if (idInterval.current) {
                clearInterval(idInterval.current)
            }

            (async () => {
                const intervalId = setInterval(verificarPagamento, 10000)
                idInterval.current = intervalId
            })()
        }
    }, [aberto, dadosPix, verificarPagamento])

    const confirmarCancelamento = useCallback(() => {
        confirm({
            title: 'Cancelar PIX',
            description: 'Tem certeza que deseja cancelar o pagamento por PIX?',
            confirmationText: 'Cancelar',
            cancellationText: 'Voltar'
        }).then(() => handleCancelar())
    }, [confirm, handleCancelar])

    useEffect(() => {
        if (!pixErro && statusPix === EnumStatusPix.Neutro && !carregando) {
            const timer = setInterval(() => {
                setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
            }, 1000);
            return () => {
                clearInterval(timer);
            };
        }
    }, [carregando, pixErro, statusPix]);

    return (
        <DialogSaurus
            aberto={aberto}
            carregando={false}
            tamanho="sm"
            fullScreen={isMobile}
            titulo={'Aguardando pagamento por PIX'}
            bottomArea={(dadosPix && statusPix === EnumStatusPix.Neutro && !pixErro && dadosPix.id !== guidEmpty()) &&
                <Box display='flex' justifyContent='center' mt={1} mb={2}>
                    <Button color='primary' variant='outlined' size='small' onClick={confirmarCancelamento}>
                        <CancelarIcon tipo='BUTTON' />
                        Cancelar Pagamento
                    </Button>
                </Box>
            }
        >
            <Box minHeight={200} height='100%'>
                {carregando && <CircularLoading tipo='FULLSIZED' />}
                {(!carregando && !pixErro && statusPix === EnumStatusPix.Neutro) && <Box display='flex' justifyContent='center'>
                    <Box textAlign={'center'} mb={1}>
                        <LinearProgress className={classes.progressBar} variant="determinate" color={"primary"} value={progress} />
                        <Typography variant="caption" color="textSecondary">A cada 10 segundos verificamos se o pagamento foi efetuado.</Typography>
                    </Box>
                </Box>}
                <PagamentoPixDetalhes
                    fechar={handleCancelar}
                    handlePedidoPix={handleRegistroPix}
                    model={dadosPix || new MovRegistrarResponseModel()}
                    pixErro={pixErro}
                    preco={pagamento.vPag}
                    statusPix={statusPix}
                />
            </Box>
        </DialogSaurus>
    );
};