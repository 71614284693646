import { MesasModel } from '../../../model/api/gestao/mesa/mesa-model';
import { useGetPedidoSalao } from 'data/api/gestao/pedido/get-pedido-salao';
import { SaloesModel } from 'model/api/gestao/saloes/saloes-model';
import { useCallback } from 'react';
import { GestaoStorageKeys, useGestaoStorage } from './gestao-storage';
import { useToastSaurus } from './toast-saurus';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import { PedidoMesaProcessadaModel } from 'model/api/gestao/pedido/pedido-mesa-processada';
import { ComandasModel } from 'model/api/gestao/comanda/comanda-model';
import { MovSimplesModel } from 'model/api/gestao/movimentacao/simples/mov-simples-model';
import { PedidoDadosModelPost } from 'model/api/gestao/pedido/pedido-dados-model';
import { PedidoClienteModel } from 'model/api/gestao/pedido/pedido-cliente-model';
import { PedidoDadosIntegracaoModel } from 'model/api/gestao/pedido/pedido-integracao-model';
import { MovSimplesProdutoModel, RastroVenda } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { PedidoProdutoModelPost, RastroPedido } from 'model/api/gestao/pedido/pedido-produto-model';
import { PedidoModelComanda } from 'model/api/gestao/pedido/pedido-comanda';
import { guidEmpty } from 'utils/guid-empty';
import { useGetPedidoSalaoProcessado } from 'data/api/gestao/pedido/get-pedido-salao-processado';
import { RetornoApiModel } from 'model/api/gestao/retorno-api-model';
import { EnumStatusProdutoPedido } from 'model/enums/enum-status-produto-pedido';
import { useGetPedidoCupom } from 'data/api/gestao/pedido/get-pedido-cupom';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { PedidoModelMesa } from 'model/api/gestao/pedido/pedido-mesa';
import { usePDV } from './pdv';
import { ImpressaoSaurus } from './impressao-saurus';
import { ConfiguracaoMesaEComanda } from 'model/app/mov-pedido-local/configuracao-mesa-e-comanda';
import { useDevice } from './device';
import { EnumMesas } from 'model/enums/enum-mesas';
import { EnumComandas } from 'model/enums/enum-comandas';
import { EnumStatusSalao } from 'model/enums/enum-status-salao';
import { useEmpresaAtual } from './empresa-atual';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { calcPercent } from 'utils/calc-percent';
import { roundTo } from 'utils/round-to';
import { useSessaoAtual } from '../providers';
import { EnumPDVTpCaixa } from 'model/enums/enum-pdv-tpcaixa';
import { useImpressaoLocal } from './impressao-local';
import { calcPercentFloor } from 'utils/calcPercentFloor';
import { isEmpty } from 'lodash';
import { EnumTpProduto } from 'model/enums/enum-tp-produto';
import { FieldsTabelaComandas } from 'database/interfaces/interface-tabela-comandas';
import { useGetPedidoSalaoById } from 'data/api/gestao/pedido/get-pedido-salao-by-id';
import { EnumTipoPedido } from 'model/enums/enum-tipo-pedido';
import { MovSimplesPessoaModel } from 'model/api/gestao/movimentacao/simples/mov-simples-pessoa-model';

export function usePedidoLocal() {
  // PROVIDERS
  const { getEmpresaSelecionada } = useSessaoAtual();
  const { getConfigByCod } = useEmpresaAtual();
  const { getRegistro, setRegistro } = useGestaoStorage();
  const { showToast } = useToastSaurus();
  const { printHtml, printNative, printType } = useDevice();
  const { getConfigByCod: getConfigPDV, getPDV, getImpressoraPdv } = usePDV();
  const { carregandoImpressaoLocal, enviarImpressaoLocal } = useImpressaoLocal();

  // CHAMADAS API
  const { getPedidoSalao, carregando: carregandoGetPedidos } =
    useGetPedidoSalao();
  const { getPedidoSalaoById, carregando: carregandoGetPedidoId } =
    useGetPedidoSalaoById();
  const {
    getPedidoSalaoProcessado,
    carregando: carregandoGetPedidosProcessado
  } = useGetPedidoSalaoProcessado();
  const { getPedidoCupom, carregando: carregandoGetCupom } =
    useGetPedidoCupom();

  // AUX
  const carregando =
    carregandoGetPedidos ||
    carregandoGetPedidosProcessado ||
    carregandoImpressaoLocal ||
    carregandoGetCupom ||
    carregandoGetPedidoId;

  const tempoOcioso = Number(
    getConfigByCod(EnumEmpresaConfig.AlertaClienteInativo) ?? '20'
  );

  const getSaloesCadastrados = useCallback(async () => {
    try {
      const saloes = (await TouchoneDBPrimary.saloes
        .filter((salao) => salao.status?.codigo === EnumStatusSalao.ATIVO)
        .toArray()) as SaloesModel[];
      return saloes;
    } catch (e: any) {
      showToast('error', 'Erro ao buscar os salões.');
      return new Array<SaloesModel>();
    }
  }, [showToast]);

  const setSalaoAtual = useCallback(
    async (salaoId: string) => {
      if (salaoId === guidEmpty()) {
        const salao = new SaloesModel();
        setRegistro(GestaoStorageKeys.SalaoAtual, salao, false);
        return;
      }

      const salaoAtual = await TouchoneDBPrimary.saloes.get({ id: salaoId });
      setRegistro(GestaoStorageKeys.SalaoAtual, salaoAtual, false);
    },
    [setRegistro]
  );

  const getSalaoAtual = useCallback((): SaloesModel | undefined => {
    const ultimoSalaoSelecionado = getRegistro(
      GestaoStorageKeys.SalaoAtual,
      false
    ) as SaloesModel;

    if (Object.entries(ultimoSalaoSelecionado).length === 0) {
      return undefined;
    }

    if (
      ultimoSalaoSelecionado &&
      ultimoSalaoSelecionado.status.codigo !== EnumStatusSalao.ATIVO
    ) {
      return undefined;
    }

    return ultimoSalaoSelecionado;
  }, [getRegistro]);

  const getMesasCadastradas = useCallback(
    async (idSalao: string) => {
      try {
        const mesas = (await TouchoneDBPrimary.mesas
          .filter((mesa) => mesa.status?.codigo === EnumMesas.ATIVO)
          .filter((mesa) => mesa.salaoId === idSalao)
          .toArray()) as MesasModel[];
        return mesas;
      } catch (e: any) {
        showToast('error', 'Erro ao buscar as mesas.');
        return new Array<MesasModel>();
      }
    },
    [showToast]
  );

  const isTodosOsSaloesPossuemMesa = useCallback(async (): Promise<boolean> => {
    const saloes = await getSaloesCadastrados();

    if (saloes.length === 0) {
      return false;
    }

    const todasAsMesas = await TouchoneDBPrimary.mesas
      .filter((mesas) => mesas.status?.codigo === EnumMesas.ATIVO)
      .toArray();

    // aqui verifico se todo salao tem mesa para não acontece de ter um salao sem mesas configurada e ser o que o garçom atende.
    const mesaPorSalao = saloes
      .filter((x) => x.status?.codigo === EnumStatusSalao.ATIVO)
      .map((salao) => {
        const mesasDoSalao = todasAsMesas.filter(
          (mesa) => mesa.salaoId === salao.id
        );

        if (mesasDoSalao.length > 0) {
          return true;
        }

        return false;
      })
      .every((element) => element === true);

    return Boolean(mesaPorSalao);
  }, [getSaloesCadastrados]);

  const verificarOciosidade = useCallback(
    (dataUltimaAtualizacaoDoPedido: string, tempoOcioso: number = 20) => {
      const dataPedidoMaisTempoOcioso = new Date(
        dataUltimaAtualizacaoDoPedido
      ).setMinutes(
        new Date(dataUltimaAtualizacaoDoPedido).getMinutes() + tempoOcioso
      );

      return dataPedidoMaisTempoOcioso > new Date().getTime() ? false : true;
    },
    []
  );

  const getMesaComandaEPedidos = useCallback(
    async (idSalao: string) => {
      let mesas: MesasModel[];

      if (!idSalao || idSalao === guidEmpty()) {
        mesas = (await TouchoneDBPrimary.mesas
          .filter((mesas) => mesas.status?.codigo === EnumMesas.ATIVO)
          .toArray()) as MesasModel[];
      } else {
        mesas = (await TouchoneDBPrimary.mesas
          .filter((mesa) => mesa.salaoId === idSalao)
          .filter((mesas) => mesas.status?.codigo === EnumMesas.ATIVO)
          .toArray()) as MesasModel[];
      }

      const saloes = await TouchoneDBPrimary.saloes
        .filter((salao) => salao.status?.codigo === EnumStatusSalao.ATIVO)
        .toArray();

      if (mesas.length === 0) {
        return [];
      }

      let responsePedido: RetornoApiModel;

      if (idSalao === guidEmpty()) {
        responsePedido = await getPedidoSalaoProcessado(
          getEmpresaSelecionada()?.Id ?? ``,
          ``,
          tempoOcioso * 60
        );
      } else {
        responsePedido = await getPedidoSalaoProcessado(
          getEmpresaSelecionada()?.Id ?? '',
          `salaoId=${idSalao}`,
          tempoOcioso * 60
        );
      }

      if (responsePedido.erro) {
        throw new Error(responsePedido.erro);
      }

      const pedidosMesa: PedidoMesaProcessadaModel[] =
        (responsePedido?.resultado?.data
          ?.list as PedidoMesaProcessadaModel[]) ?? [];

      const merge = mesas.map((mesa) => {
        const pedidoDaMesa = pedidosMesa.find(
          (pedido) => pedido.idMesa === mesa.id
        );

        if (pedidoDaMesa) {
          return {
            ...pedidoDaMesa,
            codigoMesa: mesa.codigo,
            statusMesa: mesa.status.codigo,
            nomeSalao:
              saloes.find((salao) => salao.id === pedidoDaMesa.salaoId)
                ?.descricao ?? 'Indisponível'
          };
        }

        return {
          idMesa: mesa.id,
          codigoMesa: mesa.codigo,
          statusMesa: mesa.status.codigo,
          dataUltimoPedido: '',
          pedidos: [],
          qtdePessoas: 0,
          salaoId: mesa.salaoId,
          valorTotalPedido: 0,
          dataCriacao: '',
          ociosa: false,
          nomeSalao:
            saloes.find((salao) => salao.id === mesa.salaoId)?.descricao ??
            'Indisponível'
        };
      }) as PedidoMesaProcessadaModel[];

      return merge;
    },
    [getEmpresaSelecionada, getPedidoSalaoProcessado, tempoOcioso]
  );

  const getPedidoDelivery = useCallback(
    async () => {
      let responsePedido: RetornoApiModel;

      responsePedido = await getPedidoSalao(
        getEmpresaSelecionada()?.Id ?? ``,
        `tipoPedido=${EnumTipoPedido.ENTREGA}`
      );

      if (responsePedido.erro) {
        throw new Error(responsePedido.erro);
      }

      const pedidosMesa: PedidoModel[] =
        (responsePedido?.resultado?.data?.list as PedidoModel[]) ?? [];

      return pedidosMesa;
    },
    [getEmpresaSelecionada, getPedidoSalao]
  );

  const getComandasCadastradas = useCallback(async () => {
    try {
      const comandas = (await TouchoneDBPrimary.comandas
        .filter((mesas) => mesas.status?.codigo === EnumComandas.ATIVO)
        .toArray()) as ComandasModel[];
      return comandas;
    } catch (e: any) {
      showToast('error', 'Erro ao buscar as comandas.');
      return new Array<MesasModel>();
    }
  }, [showToast]);

  const searchComandas = useCallback(
    async (query: string, campoBusca: FieldsTabelaComandas = 'codigoComanda') => {
      try {
        const comandas = (await TouchoneDBPrimary.comandas
          .filter((comandas) => comandas.status?.codigo === EnumComandas.ATIVO)
          .filter((comanda) => comanda[campoBusca] === query.toString())
          .toArray()) as ComandasModel[];
        return comandas;
      } catch (e: any) {
        showToast('error', 'Erro ao buscar comanda.');
        return new Array<ComandasModel>();
      }
    },
    [showToast]
  );

  const pedidoClienteWrapper = (clienteMovSimples: MovSimplesPessoaModel, telefone: string = '') => {
    const cliente = new PedidoClienteModel();

    cliente.referenceId = clienteMovSimples?.id ?? '';
    cliente.nomeFantasia = clienteMovSimples?.nome ?? '';
    cliente.razaoSocial = clienteMovSimples?.nome ?? '';
    cliente.cpfCnpj = clienteMovSimples?.cpfcnpj ?? '';
    cliente.ieRg = clienteMovSimples?.ierg ?? '';
    cliente.telefone = telefone;

    return cliente;
  };

  const pedidoIntegradorWrapper = () => {
    const integrador = new PedidoDadosIntegracaoModel();

    integrador.nomeIntegrador = 'TouchOne';
    integrador.cnpjIntegrador = '11914993000123';
    integrador.credencialCliente = getEmpresaSelecionada()?.Id ?? '';
    integrador.codigoAplicacao = 990009859;

    return integrador;
  };

  const pedidoDadosWrapper = (movSimplesAtual: MovSimplesModel) => {
    const pedido = new PedidoDadosModelPost();

    // atribuições do cliente
    if (movSimplesAtual.cliente) {
      pedido.cliente = pedidoClienteWrapper(movSimplesAtual.cliente, movSimplesAtual.telefone);
    }

    // por padrão a quantidade de pessoas é 1
    pedido.quantidadePessoas = 1;

    // atribuição de mesa, salao e comanda
    pedido.comandaId = movSimplesAtual.informacoesGeraisPedido.comandaId;
    pedido.salaoId = movSimplesAtual.informacoesGeraisPedido.salaoId;
    pedido.mesaId = movSimplesAtual.informacoesGeraisPedido.mesaId;
    pedido.identificador = movSimplesAtual.informacoesGeraisPedido?.identificador ?? ''

    // documento da loja
    pedido.documentoLoja = getEmpresaSelecionada()?.Documento ?? '';

    // dados integrador
    pedido.dadosIntegracao = pedidoIntegradorWrapper();

    return pedido;
  };

  const rastroVendaParaRastroPedidos = (rastros: RastroVenda[]): RastroPedido[] => {
    return rastros.map(rastro => ({
      numeroLote: rastro.nLote,
      quantidadeLote: rastro.qLote,
      dataFabricacao: rastro.dFab,
      dataValidade: rastro.dVal,
      codigoAgregacao: rastro.cAgreg,
    }))
  }

  const pedidoProdutosWrapper = (
    produtosMovSimples: MovSimplesProdutoModel[],
    salaoId: string | null = null
  ) => {
    const produtoSerializados = produtosMovSimples.map((prod) => {
      const vFinal =
        (prod.vUnCom * prod.qCom ?? 0) -
        prod.vDescUsuario +
        prod.vAcrescUsuario;

      const rastro = rastroVendaParaRastroPedidos(prod?.rastro ?? [])

      let quantidade = prod.qCom
      if (prod.qComModificador > 0) {
        quantidade = prod.qComModificador
      }

      const response = {
        setorEmpresaId: null,
        plu: prod.cProd,
        descricao: prod.xProd.length > 0 ? prod.xProd : 'Produto Avulso',
        observacao: !isEmpty(prod.infAdic) ? prod.infAdic : '',
        pesoVariavel: prod.balanca,
        codigoBarra: prod.cEan,
        status: prod.ativo
          ? EnumStatusProdutoPedido.DISPONIVEL
          : EnumStatusProdutoPedido.DESISTENCIA,
        nItem: prod.nSeq,
        posicaoMesa: '',
        codigoReferencia: prod.id,
        quantidade: quantidade,
        valorTotal: Math.round((prod.vFinal + Number.EPSILON) * 100) / 100,
        valorTotalDesconto: roundTo(prod.vDescUsuario),
        valorUnitario: roundTo(prod.vUnCom),
        valorTotalAdicional: roundTo(prod.vAcrescUsuario),
        valorTotalFrete: prod.vFrete,
        vendedor: prod.vendedorNome,
        vendedorId: prod.vendedorId,
        setorId: prod.setorId,
        salaoId: salaoId,
        indFin: prod.tpProduto === EnumTpProduto.Combo || (prod.tpProduto === EnumTpProduto.ProdutoComSubItem && prod.vUnCom === 0) ? false : prod.indFin,
        groupId: prod.idGroup,
        adicionalId: prod.idAdicional,
        subItens: pedidoProdutosWrapper(prod.prodSubItem, salaoId),
        produtoPai: prod.produtoPaiId,
        tpProduto: prod.tpProduto,
        taxaServico: prod.taxaServico ? prod.taxaServico : 0,
        valorTotalServico: prod.taxaServico
          ? roundTo(
            calcPercent(
              roundTo(vFinal),
              parseInt(
                getConfigByCod(EnumEmpresaConfig.TaxaServicoRecomendada) ??
                '0'
              )
            ) ?? 0
          )
          : 0,
        produtoIdReferencia: prod.produtoId,
        quantidadeMax: prod.qCom,
        unidadeComercial: prod.uCom,
        codigoAnvisa: prod.cProdANVISA,
        precoMaximoConsumidor: prod?.med?.vPMC ?? 0,
        numeroSequenciaReceitaMedica: prod.nSeqReceitaMedica,
        vendaControlada: (prod?.rastro?.length ?? 0) > 0,
        rastros: rastro.length === 0 ? null : rastro
      } as unknown;
      return response
    }) as PedidoProdutoModelPost[];

    return produtoSerializados;
  };

  const getBalcoesEPedidos = useCallback(
    async (idSalao: string) => {

      let saloes: SaloesModel[];

      if (idSalao === guidEmpty()) {
        saloes = (await TouchoneDBPrimary.saloes
          .filter((salao) => salao.status?.codigo === EnumStatusSalao.ATIVO)
          .toArray()) as SaloesModel[];
      } else {
        saloes = (await TouchoneDBPrimary.saloes
          .filter((salao) => salao.status?.codigo === EnumStatusSalao.ATIVO)
          .filter((salao) => salao.id === idSalao)
          .toArray()) as SaloesModel[];
      }

      if (saloes.length === 0) return [] as PedidoModelComanda[];

      let responsePedido;

      if (idSalao === guidEmpty()) {
        responsePedido = await getPedidoSalao(
          getEmpresaSelecionada()?.Id ?? '',
          ''
        );
      } else {
        responsePedido = await getPedidoSalao(
          getEmpresaSelecionada()?.Id ?? '',
          `salaoId=${idSalao}`
        );
      }

      if (responsePedido.erro) {
        throw new Error(responsePedido.erro);
      }

      const pedidos: PedidoModel[] =
        (responsePedido?.resultado?.data?.list as PedidoModel[]) ?? [];

      const pedidosSemMesa = pedidos.filter((pedido) => !pedido.mesaId);

      // const comandas = (await TouchoneDBPrimary.comandas
      //   .filter((mesas) => mesas.status?.codigo === EnumComandas.ATIVO)
      //   .toArray()) as ComandasModel[];

      // const merge = comandas.map((comanda) => {
      //   const pedidoComanda = pedidosSemMesa.find(
      //     (pedido) => pedido.comandaId === comanda.id
      //   );

      //   if (pedidoComanda) {
      //     //  valor total dos produtos relacionado a comanda corrente
      //     const valorTotal = pedidoComanda.produtos
      //       .filter(
      //         (prod) =>
      //           prod.status.codigo === EnumStatusProdutoPedido.DISPONIVEL
      //       )
      //       .reduce((acc, current) => {
      //         return acc + current.valorTotal;
      //       }, 0);

      //     return {
      //       ...pedidoComanda,
      //       valorTotalPedido: valorTotal,
      //       codigoComanda: comanda.codigoComanda,
      //       isOciosa: verificarOciosidade(
      //         pedidoComanda.systemUpdateDate as string,
      //         tempoOcioso
      //       ),
      //       nomeSalao:
      //         saloes.find((salao) => salao.id === pedidoComanda.salaoId)
      //           ?.descricao ?? ''
      //     };
      //   }

      //   return null;
      // }) as PedidoModelComanda[] | [];

      // removendo null do array
      return pedidosSemMesa.filter(function (el) {
        return el !== null;
      }) as PedidoModelComanda[];
    },
    [getEmpresaSelecionada, getPedidoSalao]
  );

  const getComandasEPedidos = useCallback(
    async (idSalao: string) => {
      let saloes: SaloesModel[];

      if (idSalao === guidEmpty()) {
        saloes = (await TouchoneDBPrimary.saloes
          .filter((salao) => salao.status?.codigo === EnumStatusSalao.ATIVO)
          .toArray()) as SaloesModel[];
      } else {
        saloes = (await TouchoneDBPrimary.saloes
          .filter((salao) => salao.status?.codigo === EnumStatusSalao.ATIVO)
          .filter((salao) => salao.id === idSalao)
          .toArray()) as SaloesModel[];
      }

      let responsePedido;

      if (idSalao === guidEmpty()) {
        responsePedido = await getPedidoSalao(
          getEmpresaSelecionada()?.Id ?? '',
          ''
        );
      } else {
        responsePedido = await getPedidoSalao(
          getEmpresaSelecionada()?.Id ?? '',
          `salaoId=${idSalao}`
        );
      }

      if (responsePedido.erro) {
        throw new Error(responsePedido.erro);
      }

      const pedidos: PedidoModel[] =
        (responsePedido?.resultado?.data?.list as PedidoModel[]) ?? [];

      const comandas = (await TouchoneDBPrimary.comandas
        .filter((mesas) => mesas.status?.codigo === EnumComandas.ATIVO)
        .toArray()) as ComandasModel[];

      const merge = comandas.map((comanda) => {
        const pedidoComanda = pedidos.find(
          (pedido) => pedido.comandaId === comanda.id
        );

        if (pedidoComanda) {
          //  valor total dos produtos relacionado a comanda corrente
          const valorTotal = pedidoComanda.produtos
            .filter(
              (prod) =>
                prod.status.codigo === EnumStatusProdutoPedido.DISPONIVEL
            )
            .reduce((acc, current) => {
              return acc + current.valorTotal;
            }, 0);

          return {
            ...pedidoComanda,
            valorTotalPedido: valorTotal,
            codigoComanda: comanda.codigoComanda,
            isOciosa: verificarOciosidade(
              pedidoComanda.systemUpdateDate as string,
              tempoOcioso
            ),
            nomeSalao:
              saloes.find((salao) => salao.id === pedidoComanda.salaoId)
                ?.descricao ?? 'Sem Salão'
          };
        }

        return null;
      }) as PedidoModelComanda[] | [];

      // removendo null do array
      return merge.filter(function (el) {
        return el != null;
      }) as PedidoModelComanda[];
    },
    [getEmpresaSelecionada, getPedidoSalao, tempoOcioso, verificarOciosidade]
  );

  const getMesaEPedidos = useCallback(
    async (idSalao: string) => {
      let saloes: SaloesModel[];

      if (idSalao === guidEmpty()) {
        saloes = (await TouchoneDBPrimary.saloes
          .filter((salao) => salao.status?.codigo === EnumStatusSalao.ATIVO)
          .toArray()) as SaloesModel[];
      } else {
        saloes = (await TouchoneDBPrimary.saloes
          .filter((salao) => salao.status?.codigo === EnumStatusSalao.ATIVO)
          .filter((salao) => salao.id === idSalao)
          .toArray()) as SaloesModel[];
      }

      if (saloes.length === 0) return [] as PedidoModelMesa[];

      let responsePedido;

      if (idSalao === guidEmpty()) {
        responsePedido = await getPedidoSalao(
          getEmpresaSelecionada()?.Id ?? '',
          ''
        );
      } else {
        responsePedido = await getPedidoSalao(
          getEmpresaSelecionada()?.Id ?? '',
          `salaoId=${idSalao}`
        );
      }

      if (responsePedido.erro) {
        throw new Error(responsePedido.erro);
      }

      const pedidos: PedidoModel[] =
        (responsePedido?.resultado?.data as PedidoModel[]) ?? [];

      const mesas = (await TouchoneDBPrimary.mesas
        .filter((mesas) => mesas.status?.codigo === EnumMesas.ATIVO)
        .toArray()) as MesasModel[];

      const merge = mesas.map((mesas) => {
        const pedidoMesa = pedidos.find((pedido) => pedido.mesaId === mesas.id);

        if (pedidoMesa) {
          //  valor total dos produtos relacionado a mesa corrente
          const valorTotal = pedidoMesa.produtos
            .filter(
              (prod) =>
                prod.status.codigo === EnumStatusProdutoPedido.DISPONIVEL
            )
            .reduce((acc, current) => {
              return acc + current.valorTotal;
            }, 0);

          return {
            ...pedidoMesa,
            valorTotalPedido: valorTotal,
            codigoMesa: mesas.codigo,
            isOciosa: verificarOciosidade(
              pedidoMesa.systemUpdateDate as string,
              tempoOcioso
            ),
            nomeSalao:
              saloes.find((salao) => salao.id === pedidoMesa.salaoId)
                ?.descricao ?? ''
          };
        }

        return null;
      }) as PedidoModelMesa[] | [];

      // removendo null do array
      return merge.filter(function (el) {
        return el != null;
      }) as PedidoModelMesa[];
    },
    [getEmpresaSelecionada, getPedidoSalao, tempoOcioso, verificarOciosidade]
  );

  const serializarPedidoComandaNaMesa = useCallback(
    async (pedidos: PedidoModel[]) => {
      if (pedidos.length === 0) {
        return [] as PedidoModelComanda[];
      }

      const comandas = await TouchoneDBPrimary.comandas
        .filter((mesas) => mesas.status?.codigo === EnumComandas.ATIVO)
        .toArray();

      const serializarPedidos = pedidos.map((pedido) => {
        const valorTotal = pedido.produtos
          .filter(
            (produto) =>
              produto.status.codigo === EnumStatusProdutoPedido.DISPONIVEL
          )
          .reduce((acc, current) => {
            return acc + current.valorTotal;
          }, 0);

        const comanda = comandas.find(
          (comanda) => comanda.id === pedido.comandaId
        );

        return {
          ...pedido,
          isOciosa: verificarOciosidade(
            pedido.systemUpdateDate as string,
            tempoOcioso
          ),
          valorTotalPedido: valorTotal,
          codigoComanda: comanda?.codigoComanda ?? '',
          nomeSalao: ''
        } as PedidoModelComanda;
      }) as PedidoModelComanda[];

      return serializarPedidos;
    },
    [tempoOcioso, verificarOciosidade]
  );

  const imprimirConsumo = useCallback(
    async (pedidoId: string, codigoReferencia: string, impressorLocal: boolean = true) => {
      const qtdColunasPDV = getConfigPDV(57);
      const impressora = getImpressoraPdv()

      const qtdColunasCalc = calcPercentFloor(Number(qtdColunasPDV ?? '64'), 75)

      const respostaImpressao = await getPedidoCupom(
        getEmpresaSelecionada()?.Id ?? '',
        pedidoId,
        qtdColunasCalc
      );

      if (respostaImpressao.erro) {
        throw new Error(
          `Erro ao imprimir os dados de consumo do pedido. Detalhe: ${respostaImpressao.erro}`
        );
      }

      const cupom = respostaImpressao.resultado?.data?.cupom;

      const imprimir = new ImpressaoSaurus('HTML');

      try {
        if (cupom) {
          const cupomTraduzido = imprimir.Traduz(cupom);

          if (!cupomTraduzido) {
            throw new Error(`Erro ao realizar impressão de consumo.`);
          }

          if (printType() === 'HTML') {
            const pdv = getPDV();

            if (pdv?.tpCaixa === EnumPDVTpCaixa.WEBPDV && impressora && impressorLocal) {
              await enviarImpressaoLocal(pedidoId, codigoReferencia, respostaImpressao.resultado?.data?.cupom, 'Consumo', qtdColunasCalc);
              return
            }

            return await printHtml(
              cupomTraduzido as string,
              Number(qtdColunasCalc || '0')
            );
          } else if (printType() === 'NATIVE') {
            const caminho = getConfigPDV(52);
            const modelo = getConfigPDV(51);

            return await printNative(
              cupom,
              Number(qtdColunasCalc || '0'),
              caminho ?? '',
              modelo ?? '',
              1
            );
          }
        } else {
          throw new Error(`Erro ao realizar impressão de consumo.`);
        }
      } catch (e: any) {
        showToast('error', e.message);
      }
    },
    [enviarImpressaoLocal, getConfigPDV, getEmpresaSelecionada, getImpressoraPdv, getPDV, getPedidoCupom, printHtml, printNative, printType, showToast]
  );

  const getConfiguracoesMesaEComanda = useCallback(() => {
    const configuracaoMesaComandas = getRegistro(
      GestaoStorageKeys.configuracoesMesaEComanda,
      false
    ) as ConfiguracaoMesaEComanda;

    if (Object.entries(configuracaoMesaComandas).length === 0) {
      return undefined;
    }

    return configuracaoMesaComandas;
  }, [getRegistro]);

  const setConfiguracaoMesaEComanda = useCallback(
    (configuracaoMesaComanda: ConfiguracaoMesaEComanda) => {
      setRegistro(
        GestaoStorageKeys.configuracoesMesaEComanda,
        configuracaoMesaComanda,
        false
      );
    },
    [setRegistro]
  );
  const carregarInfoPedido = useCallback(async (idModeloTrabalho: string, comanda: boolean = true, balcao: boolean = false, limitReq: number = 1): Promise<PedidoModel | undefined> => {
    if (balcao) {
      try {
        const pedidoId = idModeloTrabalho;
        const pedido = await getPedidoSalaoById(getEmpresaSelecionada()?.Id || '', pedidoId)

        if (pedido.erro) {
          throw new Error(pedido.erro);
        }

        return pedido.resultado?.data ?? new PedidoModel();

      } catch (err: any) {
        showToast('error', `Erro ao buscar pedido. Detalhe: ${err.message}`);
        return undefined;
      }
    }
    try {

      const pedido = await getPedidoSalao(
        getEmpresaSelecionada()?.Id ?? '',
        (!balcao && !comanda)
          ? `mesaId=${idModeloTrabalho}`
          : ((!comanda) ? `id=${idModeloTrabalho}` : `comandaId=${idModeloTrabalho}`)
      );

      if (pedido.isTimeout) {
        if (limitReq <= 2) {
          limitReq++
          await carregarInfoPedido(idModeloTrabalho, true, false, limitReq);
        } else {
          throw new Error(pedido.erro);
        }
        return
      }

      if (pedido.erro) {
        throw new Error(pedido.erro);
      }

      return pedido.resultado?.data?.list[0]
    } catch (err: any) {
      showToast('error', `Erro ao buscar pedido. Detalhe: ${err.message}`);
      return undefined;
    }
  }, [getPedidoSalaoById, getEmpresaSelecionada, showToast, getPedidoSalao]);



  return {
    // SESSAO SALÃO
    getSaloesCadastrados,
    getSalaoAtual,
    setSalaoAtual,

    // SESSAO MESA
    getMesaComandaEPedidos,
    getMesasCadastradas,
    isTodosOsSaloesPossuemMesa,
    getMesaEPedidos,

    // SESSÃO COMANDAS
    getComandasCadastradas,
    searchComandas,
    getComandasEPedidos,
    serializarPedidoComandaNaMesa,

    // SESSÃO PEDIDO
    pedidoDadosWrapper,
    pedidoProdutosWrapper,
    carregarInfoPedido,
    getPedidoDelivery,

    // SESSAO BALCAO
    getBalcoesEPedidos,

    // impressão
    imprimirConsumo,
    carregando,

    // configuracao
    getConfiguracoesMesaEComanda,
    setConfiguracaoMesaEComanda,
  };
}