import { Box, Button, Grid, Typography } from '@material-ui/core';
import { MovSimplesProdutoModel } from 'model/api/gestao/movimentacao/simples/mov-simples-produto-model';
import { ProdutoResumidoModel } from 'model/api/gestao/produto/produto/produto-resumido-model';
import { AppEventEnum } from 'model/enums/enum-app-event';
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { CardNaoEncontrado } from 'views/components/cards';
import CardCatalogo from 'views/components/cards/card-produto-catalogo/card-catalogo';
import { TristeIcon } from 'views/components/icons/triste-icon';
import { useThemeQueries } from 'views/theme';
import { useStyles } from './catalogo-list-styles';
import classNames from 'classnames';
import { guidEmpty, guidEmptyOne } from 'utils/guid-empty';
import { EstrelaVaziaIcon } from 'views/components/icons/estrela-vazia-icon';
import { ProdutoCategoriaModel } from 'model/api/gestao/produto/produto-categoria/produto-categoria-model';
import { TabelaCategorias } from 'database/interfaces/interface-tabela-categorias';
import { useHistory } from 'react-router-dom';

export interface CatalogoGridProps {
  list: Array<ProdutoResumidoModel>;
  carregando: boolean;
  onCardClicked: (produto: ProdutoResumidoModel) => void;
  onCardHolded: (produto: ProdutoResumidoModel) => void;
  refreshList: () => void;
  todasCategorias: Array<ProdutoCategoriaModel | TabelaCategorias>
  cor: string;
  categoriaId: string;
  height: number | null
}

export const CatalogoListData = (props: CatalogoGridProps) => {
  const { getMov } = useMovAtual();
  const { addHandler, removeHandler } = useEventTools();
  const { xs, md } = useThemeQueries();
  const history = useHistory();

  // const valorInicialState = xs ? 15 : md ? 28 : 40;
    const valorInicialState = 900


  const [visibleItems, setVisibleItems] = useState<number>(valorInicialState);
  const divRef = useRef<HTMLDivElement | null>(null);
  const classes = useStyles();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisibleItems(valorInicialState);
    }, 100);

    return () => clearTimeout(timeout);
  }, [valorInicialState, props.categoriaId]);

  useEffect(() => {
    const div = divRef.current;

    if (visibleItems >= props.list.length) return
    const handleScroll = () => {
      if (div && div.scrollTop + div.clientHeight + 10 >= div.scrollHeight) {
        setVisibleItems((prevVisibleItems) => prevVisibleItems + 15);
      }
    };

    div?.addEventListener('scroll', handleScroll);

    return () => {
      if (div) {
        div.removeEventListener('scroll', handleScroll);
      }
    };
  }, [props.list.length, visibleItems]);

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const produtoAlterado = React.useCallback(
    (produto: MovSimplesProdutoModel) => {
      if (!produto) return;
      props.refreshList();
    },
    [props]
  );

  const movProdAlterado = React.useCallback(
    (produto: MovSimplesProdutoModel) => {
      if (!produto) return;
      if (
        props.list.filter((x) => x.produtoGradeId === produto.produtoGradeId)
          .length > 0
      )
        forceUpdate();
    },
    [props.list]
  );

  useEffect(() => {
    addHandler(AppEventEnum.ProdutoAlterado, produtoAlterado);
    addHandler(AppEventEnum.MovAtualProdAlterado, movProdAlterado);
    return () => {
      removeHandler(AppEventEnum.ProdutoAlterado, produtoAlterado);
      removeHandler(AppEventEnum.MovAtualProdAlterado, movProdAlterado);
    };
  }, [addHandler, movProdAlterado, produtoAlterado, removeHandler]);

  const onCardHolded = (produto: ProdutoResumidoModel) => {
    props.onCardHolded(produto);
  };

  const onCardClick = (produto: ProdutoResumidoModel) => {
    props.onCardClicked(produto);
  };

  const isFavoritos = props.categoriaId === guidEmptyOne();
  const movDados = getMov();
  const exibirFavorito = props.todasCategorias.filter(x => x.id !== guidEmptyOne()).length > 0;

  const height = useMemo(() => props.height, [props.height])

  return (
    <Grid
      item
      xs={12}
      className={classNames(
        classes.listContainer,
        classes.definirTamanho,
      )}
      style={{
        height: height ?? 0
      }}
      ref={divRef}
    >
      <Grid item xs={12}>
        {props.list.length === 0 &&
          !props.carregando &&
          isFavoritos && exibirFavorito && (
            <Box px={1}>
              <CardNaoEncontrado
                mensagem={
                  <Typography
                    className={classes.categoriaSemProdutos}
                    variant="h5"
                    align="center"
                  >
                    <EstrelaVaziaIcon tipo="GERAL" />
                    <br />
                    Sem Favoritos?
                    <br />
                    <Typography variant="subtitle2">
                      Selecione uma categoria e clique na ⭐ do produto que deseja
                      favoritar.
                    </Typography>
                  </Typography>
                }
                icon={<></>}
              />
            </Box>
          )}
        {props.list.length === 0 &&
          !props.carregando &&
          isFavoritos && !exibirFavorito && (
            <Box px={1}>
              <CardNaoEncontrado
                mensagem={
                  <Typography
                    className={classes.categoriaSemProdutos}
                    variant="h5"
                    align="center"
                  >
                    <TristeIcon tipo="GERAL" />
                    <br />
                    Sem Produtos no Catálogo
                    <br />
                    <Typography variant="subtitle2">
                      Para vender itens no catálogo você precisa vinculá-los em uma categoria.
                    </Typography>
                    <Button style={{ height: 30, marginTop: 16 }} onClick={() => history.push("/produtos")} variant='outlined' color="primary">Cadastro de Produtos</Button>
                  </Typography>
                }
                icon={<></>}
              />
            </Box>
          )}
        {props.list.length === 0 &&
          !props.carregando &&
          !isFavoritos && (
            <CardNaoEncontrado
              mensagem="Categoria sem Produtos."
              icon={<TristeIcon tipo="GERAL" />}
            />
          )}
      </Grid>
      <Grid item xs={12} container spacing={1} className={classes.listContent}>
        {props.list.length > 0 &&
          props.list
            .filter((item) => {
              if (item?.ativo) {
                return true;
              }
              return false;
            })
            .slice(0, visibleItems)
            .map((produto, index) => {

              let corOverride = props.cor;
              if (props.todasCategorias && isFavoritos) {
                if (!produto.categoriaId) {
                  produto.categoriaId = guidEmpty();
                }

                const cats = props.todasCategorias.filter(x => x.id === produto.categoriaId);
                if (cats.length > 0)
                  corOverride = cats[0]?.cor ?? props.cor;
              }

              let qtde = 0;
              if (movDados?.produtos) {
                const prods = movDados.produtos.filter(
                  (x) => x.produtoGradeId === produto?.produtoGradeId && x.ativo
                );
                if (prods.length > 0) {
                }
                prods.forEach((x) => (qtde += x.qCom));
              }
              return (
                <CardCatalogo
                  qtdeCarrinho={qtde}
                  exibirFavorito={exibirFavorito}
                  onHold={onCardHolded}
                  onClick={onCardClick}
                  model={produto}
                  cor={corOverride}
                  key={produto.id}
                />
              );
            })}
      </Grid>
    </Grid>
  );
};
