import * as Yup from 'yup';

export const useFormDashboardValidation = () => {

    const FormDashboardYupValidation = Yup.object().shape({
        dataInicial: Yup.date().required('Campo obrigatório').typeError('Campo obrigatório'),
        dataFinal: Yup.date().required("Campo obrigatório").typeError('Campo obrigatório')
            .test(
                "data-final-menor",
                "Data Final não pode ser menor que a Data Inicial",
                (dataFinal, cc) => {
                    return new Date(cc.parent.dataInicial).getTime() <= new Date(dataFinal ?? '').getTime()
                }),
    });

    return {
        FormDashboardYupValidation
    }
}

