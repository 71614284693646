import { useEffect, useState, useCallback, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { useStyles } from './novo-pagamento-list-styles';
import NovoPagamentoListData from './novo-pagamento-list-data';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useCadastros, useToastSaurus } from 'services/app';
import {
  CredenciamentoSafra,
  FinalizadoraModel
} from 'model/api/gestao/finalizadora/finalizadora-model';
import { toDecimal, toDecimalString } from 'utils/to-decimal';
import { useHistory, useLocation } from 'react-router-dom';
import { guidEmpty } from 'utils/guid-empty';
import { useMovInsercaoStorage } from 'services/app/hooks/mov-insercao-storage';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { EnumPagTpMod, EnumPagTpTransacao } from 'model';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { useConfirm } from 'material-ui-confirm';
import { isEmpty } from 'lodash';
import { VariaveisAmbiente } from 'config';
import { EnumDeviceType } from 'model/enums/enum-device-type';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { usePDV } from 'services/app/hooks/pdv';
import PagamentoParcialEfetuarFragment from '../pagamento-parcial/pagamento-parcial-efetuar-fragment/pagamento-efetuar-fragment';
import { roundTo } from 'utils/round-to';
import { stringNumeros } from 'utils/string-numeros';

export const NovoPagamentoList = () => {
  // PROVIDERS
  const {
    getMov,
    iniciarPagamento,
    carregandoPgto,
    carregando: carregandoMov,
    retornaFluxoVenda,
    saveQtdPessoasPagamento,
    getQtdPessoasPagamento
  } = useMovAtual();
  const { getInsercaoFavorita } = useMovInsercaoStorage();
  const { addHandler, removeHandler, callEvent } = useEventTools();
  const { abrirParcelamento } = useCadastros();

  // STATES E REFS
  const vDigitado = useRef<number>(0);
  const nroParcelas = useRef<number>(0);
  const payment = useRef<FinalizadoraModel>(new FinalizadoraModel());
  const valorDigitadoConfirmado = useRef<number>(0);
  const location = useLocation();
  const { pessoas } = getQtdPessoasPagamento();
  const [qtdePessoas, setQtdePessoas] = useState<number>(
    typeof pessoas === 'number' ? pessoas : 1
  );
  const confirm = useConfirm();
  const [modalEditAberto, setModalEditAberto] = useState(false);
  const { selClienteDesativado } = usePDV();
  const digitado = useRef<number>(0);
  const [tpPayments, setTpPayments] = useState<EnumPagTpMod[] | undefined>();
  const { abrirCadastroFinalizadora } = useCadastros();

  const [queryStatus, setQueryStatus] = useState({
    page: 1,
    totalPages: 0,
    totalResults: 0,
    list: Array<FinalizadoraModel>()
  });
  const [selectedList, setSelectedList] = useState<Array<string>>([]);

  const mov = getMov();
  const valorAPagar = useRef<number>(
    valorDigitadoConfirmado.current === 0
      ? qtdePessoas > 1
        ? ((mov?.vNF ?? 0) - (mov?.vPago ?? 0)) / qtdePessoas
        : (mov?.vNF ?? 0) - (mov?.vPago ?? 0)
      : valorDigitadoConfirmado.current
  );

  const carregando = carregandoPgto || carregandoMov;

  // AUX
  const classes = useStyles({ paymentScreen: false });
  const {push} = useHistory();
  const { showToast } = useToastSaurus();

  useEffect(() => {
    callEvent(AppEventEnum.AlterarMenuPDV, false);
    addHandler(AppEventEnum.AtualizarQuantidadeDePessoas, setQtdePessoas);
    addHandler(AppEventEnum.MovAtualAlterada, setQtdePessoas);

    return () => {
      callEvent(AppEventEnum.AlterarMenuPDV, true)
      removeHandler(AppEventEnum.AtualizarQuantidadeDePessoas, setQtdePessoas);
    };
  }, [callEvent, addHandler, removeHandler]);

  //VALIDACAO DE ENTRADA NA TELA
  useEffect(() => {
    if ((mov && mov!.vNF <= 0) || mov!.vNF === undefined) {
      showToast(
        'info',
        'Esta venda não possui valor para realizar o pagamento.'
      );
      const rota = getInsercaoFavorita();
      push(rota);
    }
  }, [getInsercaoFavorita, getMov, mov, push, showToast]);

  const inserirPagamento = useCallback(
    async (
      paymentMethod: FinalizadoraModel,
      valorPaga: number = 0,
      numeroParcelas: number = 0
    ) => {
      try {
        const ret = await retornaFluxoVenda();
        if (ret.url.indexOf('pagamento') === -1) {
          showToast('error', ret.msg);
          push(ret.url);
          return;
        }

        if (!mov?.vNF || mov?.vNF === 0) {
          throw new Error(
            'Valor de nota zerado, não é possivel realizar um pagamento'
          );
        }

        if (valorAPagar.current === 0) {
          throw new Error(
            'Não é possível realizar um pagamento com valor zerado.'
          );
        }

        const pagamento = {
          adquirente: '',
          bandeira: '',
          nomeCartao: '',
          numCartao: '',
          tid: guidEmpty(),
          tpTransacao: paymentMethod.tpTransacao,
          modPagamento: paymentMethod.tpMod,
          nParcelas: numeroParcelas > 0 ? numeroParcelas : nroParcelas.current,
          dhTransacao: new Date(),
          cAut: '',
          codNsu: '',
          descontoAplicado: 0,
          acrescimoAplicado: 0,
          vPag: valorPaga === 0 ? valorAPagar.current : valorPaga,
          envioAPI: '',
          retornoAPI: '',
          status: 0,
          viaCliente: '',
          viaLojista: '',
          pagamentoId: paymentMethod.id,
          vTroco: 0,
          cnpj: paymentMethod.cnpjCred,
          cancelado: false
        };

        const credenciais = paymentMethod.credenciais
          ? JSON.parse(paymentMethod.credenciais.replaceAll("'", '"'))
          : new CredenciamentoSafra();
        if (paymentMethod.tpTransacao === EnumPagTpTransacao.S2_PAY) {
          push({
            pathname: '/venda-simples/processando-pagamento',
            state: {
              modoPagamento: pagamento,
              credenciais
            }
          });
          return;
        }

        await iniciarPagamento(
          pagamento,
          true,
          credenciais,
          paymentMethod.credenciado,
          tpPayments
        );

        vDigitado.current = 0;
        valorDigitadoConfirmado.current = 0;
        nroParcelas.current = 0;

        const novaMov = getMov();

        if (novaMov!.vPago >= novaMov!.vNF) {
          setQtdePessoas(1);
          if (!novaMov?.clienteIdentificado && !selClienteDesativado()) {
            push('/venda-simples/identificar-cliente');
            return;
          }

          return push('/venda-simples/finalizar-venda');
        } else {
          let novaqtde = qtdePessoas;

          if (qtdePessoas - 1 > 0) {
            novaqtde = qtdePessoas - 1;
            setQtdePessoas(novaqtde);
            saveQtdPessoasPagamento(novaqtde);
          }

          let proximoPagamento = toDecimal(
            ((novaMov?.vNF ?? 0) - (novaMov?.vPago ?? 0)) / novaqtde,
            2
          );

          valorAPagar.current = proximoPagamento;
          showToast('success', `Pagamento parcial realizado!`);
        }
      } catch (err: any) {
        vDigitado.current = 0;
        valorDigitadoConfirmado.current = 0;
        nroParcelas.current = 0;
        showToast('error', err.message);
      }
    },
    [retornaFluxoVenda, mov?.vNF, iniciarPagamento, tpPayments, getMov, showToast, push, selClienteDesativado, qtdePessoas, saveQtdPessoasPagamento]
  );

  const fillResult = useCallback(
    (
      page: number,
      totalPages: number,
      totalResults: number,
      list: Array<FinalizadoraModel>
    ) => {
      setQueryStatus({
        page: page,
        list: list,
        totalResults: totalResults,
        totalPages: totalPages
      });
    },
    []
  );

  const search = useCallback(async () => {
    try {
      const res = await TouchoneDBPrimary.finalizadoras.toArray();
      fillResult(1, 1, res.length, res as FinalizadoraModel[]);
    } catch (e: any) {
      showToast('error', e.message);
    }
  }, [showToast, fillResult]);

  const handleCardClicked = useCallback(
    async (paymentMethod: FinalizadoraModel) => {
      try {
        // Parcelamento do pagamento
        if (valorAPagar.current === 0) {
          throw new Error(
            'Não é possível realizar um pagamento com valor zerado.'
          );
        }
        const arrayParcelamento = () => {
          const arrayParcelamento: number[] = [];
          for (let i = 1; i <= paymentMethod.qMaxParc; i++) {
            arrayParcelamento.push(i);
          }
          return arrayParcelamento;
        };

        const arrayParc = arrayParcelamento().filter((item: number) => {
          return valorAPagar.current / item >= paymentMethod.vMinParc;
        });

        if (
          paymentMethod.qMaxParc > 1 &&
          paymentMethod.vMinParc < (mov?.vNF ?? 0) - (mov?.vPago ?? 0) &&
          arrayParc.length !== 1
        ) {
          const parapagar = valorAPagar.current;

          abrirParcelamento(
            async (parcelas: number) => {
              await inserirPagamento(paymentMethod, parapagar, parcelas);
              callEvent(AppEventEnum.AlterarDisplayKeybordPayment, { valor: stringNumeros(toDecimalString(valorAPagar.current)) });
            },
            qtdePessoas,
            parapagar,
            paymentMethod
          );
        } else {
          await inserirPagamento(paymentMethod);
          callEvent(AppEventEnum.AlterarDisplayKeybordPayment, { valor: stringNumeros(toDecimalString(valorAPagar.current)) });
        }
      } catch (e: any) {
        showToast('error', e.message);
      } finally {
        callEvent(AppEventEnum.PagamentoEfetuado, true);
      }
    },
    [
      mov?.vNF,
      mov?.vPago,
      abrirParcelamento,
      qtdePessoas,
      inserirPagamento,
      callEvent,
      showToast
    ]
  );

  const onCardClicked = useCallback(
    async (paymentMethod: FinalizadoraModel) => {
      payment.current = paymentMethod;
      if (
        paymentMethod.tpTransacao === EnumPagTpTransacao.S2_PAY &&
        VariaveisAmbiente.paymentDevice === EnumDeviceType.NAVIGATOR
      ) {
        const credenciais: CredenciamentoSafra | null =
          paymentMethod.credenciais
            ? JSON.parse(paymentMethod.credenciais)
            : null;

        if (
          !credenciais ||
          isEmpty(credenciais.merchantToken) ||
          isEmpty(credenciais.codigoAtivacao)
        ) {
          confirm({
            title: 'TEF Detectado',
            description:
              'Aparentemente você está com um dispositivo TEF conectado ao seu aparelho, porém a sua Forma de Pagamento não tem credenciais para usá-la, deseja configurar?',
            confirmationText: 'Configurar',
            cancellationText: 'Usar outra forma'
          }).then(() =>
            abrirCadastroFinalizadora(
              paymentMethod.id,
              location.pathname,
              false,
              true
            )
          );
          return;
        }
      }
      await handleCardClicked(paymentMethod);
    },
    [abrirCadastroFinalizadora, confirm, handleCardClicked, location.pathname]
  );

  const onCardChecked = useCallback(
    (model: FinalizadoraModel) => {
      const aux = [...selectedList];
      aux.push(model.id);
      setSelectedList(aux);
    },
    [selectedList]
  );

  const modalEdit = useCallback(({ openned }: any) => {
    setModalEditAberto(openned);
  }, []);

  const modalEditCredenciar = useCallback(({ aberto }: any) => {
    setModalEditAberto(aberto);
  }, []);

  const modalEditDesconto = useCallback(({ aberto }: any) => {
    setModalEditAberto(aberto);
  }, []);

  const updateValor = useCallback(() => {
    const movAtualizada = getMov();
    valorAPagar.current =
      valorDigitadoConfirmado.current === 0
        ? qtdePessoas > 1
          ? ((movAtualizada?.vNF ?? 0) - (movAtualizada?.vPago ?? 0)) /
          qtdePessoas
          : (movAtualizada?.vNF ?? 0) - (movAtualizada?.vPago ?? 0)
        : valorDigitadoConfirmado.current;
  }, [getMov, qtdePessoas]);

  useEffect(() => {
    (async () => {
      if (!modalEditAberto) {
        await search();
      }
    })();
    addHandler(AppEventEnum.FinalizadoraModal, modalEdit);
    addHandler(AppEventEnum.DialogCredenciarPix, modalEditCredenciar);
    addHandler(AppEventEnum.DialogAdicionarAcrescDesc, modalEditDesconto);

    return () => {
      removeHandler(AppEventEnum.FinalizadoraModal, modalEdit);
      removeHandler(AppEventEnum.DialogCredenciarPix, modalEditCredenciar);
      removeHandler(AppEventEnum.DialogAdicionarAcrescDesc, modalEditDesconto);
    };
  }, [
    addHandler,
    modalEdit,
    modalEditAberto,
    modalEditCredenciar,
    modalEditDesconto,
    removeHandler,
    search
  ]);

  useEffect(() => {
    if (!modalEditAberto) {
      updateValor();
    }
  }, [modalEditAberto, updateValor]);

  const textChanged = useCallback(
    async (text: string, formattedText: string) => {
      try {
        digitado.current = toDecimal(formattedText, 2);
        valorAPagar.current = toDecimal(formattedText, 2);
        return true;
      } catch (e: any) {
        showToast(
          'error',
          'Erro ao inserir o valor avulso. Detalhe: ' + e.message
        );
        return false;
      }
    },
    [showToast]
  );

  useEffect(() => {
    const redirect = (e: any) => {
      e.preventDefault();
      push('/venda-simples/carrinho');
    }
    window.addEventListener('popstate', redirect)
    return () => {
      window.removeEventListener('popstate', redirect)
    }
  }, [push])

  return (
    <>
      {(carregando || carregandoPgto) && <CircularLoading tipo="FULLSIZED" />}

      <Grid direction="column" className={classes.root}>
        <Grid className={classes.keyboardArea} style={{ flex: 1 }}>
          <PagamentoParcialEfetuarFragment
            valorDigitadoConfirmadoRef={valorDigitadoConfirmado}
            vRestante={(mov?.vNF ?? 0) - (mov?.vPago ?? 0)}
            vPago={mov?.vPago ?? 0}
            vPessoa={toDecimal(
              roundTo(((mov?.vNF ?? 0) - (mov?.vPago ?? 0)) / qtdePessoas, 2),
              2
            )}
            textChanged={textChanged}
          />
        </Grid>

        <Grid className={classes.buttonContainer}>
          <NovoPagamentoListData
            carregando={carregando}
            list={queryStatus.list}
            selectedList={selectedList}
            onCardClicked={onCardClicked}
            onCardChecked={onCardChecked}
            setTpPayments={setTpPayments}
            paymentScreen
          />
        </Grid>
      </Grid>
    </>
  );
};
