import { Grid, Typography } from '@material-ui/core';
import { useStyles } from './opcoes-list-data-styles';
import { MenuModel } from 'model/app';
import { useCallback, useEffect, useState } from 'react';
import { stopPropagationWithFeedback } from 'utils/custom-stop-propagation';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { EnumMenu } from 'model';
import { useSessaoAtual, useToastSaurus } from 'services/app';
import {
  isPlanoComVendedor,
  isPlanoControleMesasComandas,
  isPlanoDeliveryProprio,
  isPlanoFarmaceutico
} from 'utils/plano-utils';
import { VariaveisAmbiente } from 'config';
import { ModulosPDVMock } from 'data/mocks/menu-pdv-mock';
import { useMenuFavoritos } from 'services/app/hooks/menu-favoritos';
import CardOpcao from 'views/components/cards/card-opcao/card-opcao';
import { retornaNomeModuloPai } from 'utils/retorna-nome-modulo-pai';
import { EnumInsercaoFavorita } from 'model/enums/enum-insercao-favorita';
import { useOpenMovRota } from 'services/app/hooks/open-mov-rota';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { usePDV } from 'services/app/hooks/pdv';
import { EnumPDVConfigCod } from 'model/enums/enum-pdv-config';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { InsercaoFavoritaMock } from 'data/mocks/insercao-favorita-mock';
import { usePedidoDelivery } from 'services/app/hooks/pedido-delivery';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { EnumTipoTrabalho } from 'model/enums/enum-tipo-trabalho';
import { farmaceuticoMenuEnumPermissao } from 'data/mocks/farmaceutico-menu-enum-permissao';

export const OpcoesListData = () => {
  // STATES E REFS
  const [modulosRetornados, setModulosRetornados] = useState([] as MenuModel[]);

  // HOOKS
  const { setFavorito, getFavoritos } = useMenuFavoritos();
  const { addHandler, removeHandler, callEvent } = useEventTools();
  const { plano } = useSessaoAtual();
  const { showToast } = useToastSaurus()
  const { actionMenuPDV } = useOpenMovRota()
  const { getConfigByCod } = useEmpresaAtual()
  const { isPedidoDelivery } = usePedidoDelivery()
  const { getConfiguracoesMesaEComanda } = usePedidoLocal()
  const { getConfigByCod: getConfigPDV } = usePDV()
  const modeloTrabalho = getConfigPDV(EnumPDVConfigCod.ModeloTrabalho)
  const solicitarClientePedido = getConfigByCod(EnumEmpresaConfig.CamposSolicitarClientePedido)
  const solicitarClienteVenda = getConfigByCod(EnumEmpresaConfig.CamposSolicitarClienteVenda)
  const planoDeliveryProprio = isPlanoDeliveryProprio(plano?.plano)
  const isFarmaceutico = isPlanoFarmaceutico(plano?.plano);

  const soliClientesPorModeloTrabalho = useCallback(() => {
    if (modeloTrabalho === '2') {
      return Number(solicitarClientePedido)
    }

    return Number(solicitarClienteVenda)
  }, [modeloTrabalho, solicitarClientePedido, solicitarClienteVenda])

  //  AUX
  const classes = useStyles();

  const serializeMenu = useCallback(() => {
    const ret = ModulosPDVMock
      .filter((modulo) => modulo.codigoPai !== 0)
      .filter((x) => {
     
        if (isFarmaceutico && (farmaceuticoMenuEnumPermissao.includes(x.codigo))) {
          return false
        }
        if (
          !isPlanoComVendedor(plano?.plano) &&
          EnumMenu.OPERACIONAL_DENTROVENDA_SELECIONAR_VENDEDOR === x.codigo
        ) {
          return false;
        }
        const naoApareceCodigo = [
          EnumMenu.OPERACIONAL_DENTROVENDA_RESUMO_ULTIMA_VENDA,
          EnumMenu.OPERACIONAL_DENTROVENDA_IDENTIFICAR_CLIENTE,
        ].includes(x.codigo)
        const naoApareceCodigoPai = [
          EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_OPERACOES_SALOES,
          EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_FINALIZAR_VENDA
        ].includes(x.codigoPai)

        if (soliClientesPorModeloTrabalho() === 0 && x.codigo === EnumMenu.OPERACIONAL_DENTROVENDA_IDENTIFICAR_CLIENTE) {
          return false
        }
        if (!planoDeliveryProprio && x.codigo === EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_DELIVERY) {
          return false
        }
        if (isPedidoDelivery()) {
          if (naoApareceCodigoPai || naoApareceCodigo) {
            return false
          }
        }
        if (
          !VariaveisAmbiente.performanceDebugMode &&
          EnumMenu.RELATORIOS_PERFORMANCE === x.codigo
        ) {
          return false;
        }
        if (
          !isPlanoControleMesasComandas(plano?.plano) &&
          ([EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_BALCAO,
          EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_COMANDA,
          EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_MESA,
          EnumMenu.OPERACIONAL_CONTROLESALAO_GERAR_COMANDA,
          EnumMenu.OPERACIONAL_DENTROVENDA_ACOMPANHAMENTO_PEDIDOS].includes(x.codigo))
        ) {
          return false;
        }

        if (getConfiguracoesMesaEComanda()?.tipoTrabalho !== EnumTipoTrabalho.COMANDA &&
          x.codigo === EnumMenu.OPERACIONAL_CONTROLESALAO_GERAR_COMANDA) {
          return false
        }

        return true;
      })
      .filter((x) => x.codigoGrupo === EnumMenu.PDV)
      .map((item) => {
        const favorito = getFavoritos().find(
          (fav) => fav.codigo === item.codigo
        );
        if (favorito) {
          favorito.descricao = item.descricao;
          favorito.nome = item.nome;
          favorito.rota = item.rota;
          return favorito;
        }
        return item;
      });

    setModulosRetornados(ret);
  }, [getConfiguracoesMesaEComanda, getFavoritos, isFarmaceutico, isPedidoDelivery, plano?.plano, planoDeliveryProprio, soliClientesPorModeloTrabalho]);

  useEffect(() => {
    serializeMenu();
  }, [serializeMenu]);

  useEffect(() => {
    addHandler(AppEventEnum.MenuPDVFavorito, serializeMenu);
    return () => {
      removeHandler(AppEventEnum.MenuPDVFavorito, serializeMenu);
    };
  }, [addHandler, removeHandler, serializeMenu]);

  const handleClick = useCallback(
    (opcao: MenuModel) => {
      actionMenuPDV(opcao.codigo, opcao.rota);
    },
    [actionMenuPDV]
  );

  const preventCardClick = useCallback((event: any) => {
    try {
      stopPropagationWithFeedback(event);
      event.preventDefault();
    } catch (e: any) { }
  }, []);

  const handleClickAddFavorite = useCallback(
    (opcao: MenuModel) => {
      setFavorito(opcao);
    },
    [setFavorito]
  );

  const getValorPadrao = useCallback((rota: EnumInsercaoFavorita) => {
    return InsercaoFavoritaMock.find(mock => mock.Key === rota)?.Value ?? ''
  }, [])

  const handleClickAddPadrao = useCallback(
    (rota: EnumInsercaoFavorita) => {
      callEvent(AppEventEnum.EstrelaFavorito, rota);
      showToast('success', `O modo "${getValorPadrao(rota)}" foi adicionado como padrão.`)
    },
    [callEvent, getValorPadrao, showToast]
  );

  return (
    <>

      <Grid item spacing={2} className={classes.containerListCard}>
        {modulosRetornados.length > 0 &&
          modulosRetornados.map((opcao, index) => {
            return (
              <>
                {(index === 0 ||
                  modulosRetornados[index - 1].codigoPai !==
                  opcao.codigoPai) && (
                    <>
                      <Grid xs={12} className={classes.containertitle}>
                        <Typography className={classes.title}>
                          {isFarmaceutico && opcao.codigoPai === EnumMenu.OPERACIONAL_DENTROVENDA_GROUP_OPERACOES_SALOES ? "Operações de Venda" : retornaNomeModuloPai(opcao.codigoPai)}
                        </Typography>
                      </Grid>
                      <br />
                    </>
                  )}
                <CardOpcao
                  model={opcao}
                  key={opcao.id}
                  handleClickAddPadrao={handleClickAddPadrao}
                  handleFavorite={handleClickAddFavorite}
                  preventCardClick={preventCardClick}
                  onClick={handleClick}
                />
              </>
            );
          })}
      </Grid>
    </>
  );
};
