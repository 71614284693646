import { Button, Grid } from '@material-ui/core';
import { picker } from 'utils/picker';
import { DefaultFormRefs } from 'views/components/form/utils';
import { useRef, useCallback, useEffect } from 'react';
import { ModalHeader } from 'views/components/modals/components/modal-header/modal-header';
import { ButtonModalHeader } from 'views/components/controles/buttons/button-modal-header/button-modal-header';
import { useToastSaurus, useCadastros } from 'services/app';
import { SalvarNovoIcon, VoltarIcon } from 'views/components/icons';
import { useModalStyles } from 'views/components/modals/utils/modal-styles';
import { CircularLoading } from 'views/components/utils/circular-loading/circular-loading';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { usePostConversaoPessoaCSV, usePostConversaoProdutoCSV } from 'data/api/gestao/carga/post-carga';
import { UploadCargaCadastroFormModel } from 'model/app/forms/carga/upload-carga-cadastro-form-model';
import { FormUploadCargaCadastro } from 'views/components/form/upload-carga/form-upload-cadastro/form-upload-carga-cadastro';
import { EnumUploadCargaTipo } from 'model/enums/enum-upload-carga-tipo';
import { useShowAviso } from 'services/app/hooks/show-aviso';

export const UploadCargaCadastro = () => {
  const { postCargaPessoas, carregando: carregandoPessoa } = usePostConversaoPessoaCSV();
  const { postCargaProdutos, carregando: carregandoProdutos } = usePostConversaoProdutoCSV();

  const carregando = carregandoPessoa || carregandoProdutos;

  const { fecharCadastroUploadCarga } = useCadastros();

  const cadUploadCargaFormRef =
    useRef<DefaultFormRefs<UploadCargaCadastroFormModel>>(null);
  const redirect = useRef<boolean>(true);
  const classes = useModalStyles();

  useEffect(() => {
    cadUploadCargaFormRef.current?.fillForm(new UploadCargaCadastroFormModel());
  }, []);

  const history = useHistory();

  const { showAviso } = useShowAviso();
  const { showToast } = useToastSaurus();
  const saveNewCarga = useCallback(
    async (model: UploadCargaCadastroFormModel) => {
      try {
        let ret;
        if (model.tipo === EnumUploadCargaTipo.PESSOA) {
          ret = await postCargaPessoas(model.conversao);
        } else {
          ret = await postCargaProdutos(model.conversao);
        }
        if (ret.erro) {
          throw ret.erro;
        }
        if (redirect.current) {
          fecharCadastroUploadCarga();
          return history.push(`/upload-carga/detalhes/${ret.resultado?.data.id}`);
        } else {
          cadUploadCargaFormRef.current?.resetForm();
        }
        if (!redirect.current) {
          showToast(
            'success',
            'Carga enviada com sucesso!',
            2000,
            'bottom-center',
            60,
          );
        }
      } catch (e: any) {
        let mensagemErro: string[] = []
        let erros: string[] = e.message.split('.');
        erros = erros.map((x: string) => x.includes(':') ? x.split(':')[1] : x.includes(']') ? x.split(']')[1] : x);
        mensagemErro = erros.reduce<string[]>((prev, curr) => {
          if (prev.includes(curr) || !curr) {
            return [...prev]
          }
          return [...prev, curr]
        }, [])

        let finalMessage = mensagemErro.join('/n')
        showAviso(
          'error',
          finalMessage,
        );
      }
    },
    [postCargaPessoas, postCargaProdutos, fecharCadastroUploadCarga, history, showToast, showAviso],
  );

  const handleSubmit = useCallback(
    async (modelF: UploadCargaCadastroFormModel) => {
      const UploadCargaToCreate = picker<UploadCargaCadastroFormModel>(
        modelF,
        new UploadCargaCadastroFormModel(),
      );

      return saveNewCarga(UploadCargaToCreate);
    },
    [saveNewCarga],
  );

  const submitForm = useCallback((redirectToEdit: boolean) => {
    redirect.current = redirectToEdit;
    cadUploadCargaFormRef.current?.submitForm();
  }, []);

  const onCloseClick = useCallback(() => {
    fecharCadastroUploadCarga();
  }, [fecharCadastroUploadCarga]);

  return (
    <div className={classes.root}>
      {carregando ? <CircularLoading tipo="FULLSIZED" /> : null}
      <ModalHeader
        title={'Upload de Carga'}
        leftArea={
          <ButtonModalHeader
            tooltip="Voltar"
            icon={<VoltarIcon tipo="MODAL_HEADER" />}
            onClick={onCloseClick}
          />
        }
      />
      <div className={classes.content}>
        <div
          className={classNames(
            classes.contentForms,
            carregando ? classes.contentFormsLoading : undefined,
          )}
        >
          <FormUploadCargaCadastro
            ref={cadUploadCargaFormRef}
            showLoading={false}
            loading={carregando}
            onSubmit={handleSubmit}
          />
        </div>
        <div className={classes.acoes}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Button
                disabled={carregando}
                onClick={() => submitForm(true)}
                variant="contained"
                color="primary"
                fullWidth
              >
                <SalvarNovoIcon tipo="BUTTON_PRIMARY" />
                Salvar Carga
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default UploadCargaCadastro;
