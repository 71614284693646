import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
    },
    containerImg: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing(2)
    },
    cardImg: {
        display: "flex",
        objectFit: "contain",
        zIndex: 1,
        position: 'relative',
        background: theme.palette.common.white,
        width: "200px",
        height: "200px",
        borderRadius: "5px",
        transition: "0.2s ease-in-out transform",
    },
    infoProd: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    textInfo: {
        fontSize: '1.5rem',
        width: '100%',
        fontWeight: 600,
        lineHeight: '32px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'center'
    },
    containerButton: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonChanged: {
        width: '55px',
        height: '55px',
        padding: '0!important',
        borderRadius: '50%',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    prefix: {
        fontSize: '38px',
        fontWeight: 600,
    },
    textPerson: {
        fontSize: '35px',
        lineHeight: '10px',
        fontWeight: 700,
        textAlignLast: 'center',
        width: '100px', 
        '& div':{
            marginLeft:'0'
        }
    },
    buttonQuantidade: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    divider: {
        background: theme.palette.grey[400],
    }
}));
