import { Button, Grid, Typography } from '@material-ui/core';
import { usePostPedidoDados } from 'data/api/gestao/pedido-dados/post-pedido-dados';
import { useGetPedidoSalao } from 'data/api/gestao/pedido/get-pedido-salao';
import { PedidoModel } from 'model/api/gestao/pedido/pedido-model';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import {
  GestaoStorageKeys,
  useCadastros,
  useGestaoStorage,
  useToastSaurus,
} from 'services/app';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { PrivatePageHeader } from 'views/components/headers';
import { AtualizarIcon, CancelarIcon } from 'views/components/icons';
import { useDefaultMovimentacaoStyles } from '../components/styles/default-movimentacao-styles';
import { useStyles } from './mov-enviar-pedido-page-styles';
import { LoadingFinalizacao } from 'views/components/utils/loading-finalizacao/loading-finalizacao';
import { EnumTipoMovimentacao } from 'model/enums/enum-tipo-movimentacao';
import { usePostPedidoProduto } from 'data/api/gestao/pedido-produto/post-pedido-dados-produtos';
import { EnumTipoTrabalho } from 'model/enums/enum-tipo-trabalho';
import { EnumStatusPedido } from 'model/enums/enum-status-pedido';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { guidEmpty } from 'utils/guid-empty';
import { useSessaoAtual } from 'services/app';
import { useContratoAtual } from 'services/app/hooks/contrato-atual';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { EnumBalcaoSalao } from 'model/enums/enum-balcao-salao';
import { isEmpty } from 'lodash';
import { usePDV } from 'services/app/hooks/pdv';
import { useEmpresaAtual } from 'services/app/hooks/empresa-atual';
import { EnumEmpresaConfig } from 'model/enums/enum-empresa-config';
import { EnumCodigosPermissoes } from 'model/enums/enum-codigos-permissoes';
import { MenuCirculadoIcon } from 'views/components/icons/menu-circulado';
import { useOpenMovRota } from 'services/app/hooks/open-mov-rota';
import { usePostPedidoReceita } from 'data/api/gestao/pedido-receita/post-pedido-receita';
import { useGetHealth } from 'data/api/gestao/healthy';
import { EnumModeloDeTrabalho } from 'model/enums/enum-modelo-de-trabalho';
import { VendaIcon } from 'views/components/icons/venda-icon';
import { useThemeQueries } from 'views/theme';
import { ButtonPrivateHeader } from 'views/components/controles';

export const MovEnviarPedidoPage = () => {
  // PROVIDERS
  const {
    getMov,
    setPedidoExistente,
    carregando: carregandoProvider,
    setSalaoId,
    setMesaId,
    setComandaId,
    setTipoMovimentacao,
    setIdentificadorPager
  } = useMovAtual();
  const { delRegistro } = useGestaoStorage();
  const {
    pedidoDadosWrapper,
    pedidoProdutosWrapper,
    getConfiguracoesMesaEComanda,
    getSaloesCadastrados,
  } = usePedidoLocal();
  const { getEmpresaSelecionada, getPermissaoBoolean } = useSessaoAtual();
  const { getConfigByCod: getContratoConfigByCod } = useContratoAtual()
  const { getConfigByCod } = useEmpresaAtual()
  const { getConfigByCod: getConfigPDV } = usePDV();
  const { abrirSolicitarPermissao } = useCadastros();

  // CHAMADAS API
  const { getPedidoSalao, carregando: carregandoGetPedidos } =
    useGetPedidoSalao();
  const { postPedidoDados, carregando: carregandoPostPedidoDados } =
    usePostPedidoDados();
  const { postPedidoProdutos, carregando: carregandoPostProdutos } =
    usePostPedidoProduto();
  const { postPedidoReceita, carregando: carregandoPostReceitas } =
    usePostPedidoReceita();
  const { getHealth, carregando: carregandoHealth } = useGetHealth();

  // AUX
  const carregando =
    [
      carregandoProvider,
      carregandoGetPedidos,
      carregandoPostPedidoDados,
      carregandoPostReceitas,
      carregandoPostProdutos,
      carregandoHealth
    ].includes(true)

  const { isMobile } = useThemeQueries();
  const { push: historyPush } = useHistory();
  const classes = useStyles();
  const [error, setError] = useState<Error>();
  const movStyles = useDefaultMovimentacaoStyles();
  const { showToast } = useToastSaurus();
  const { goToAdministrativo } = useOpenMovRota()

  const leftArea = React.useCallback(
    () =>
      isMobile ? (
        <ButtonPrivateHeader
          icon={<MenuCirculadoIcon tipo="PRIVATE_HEADER" />}
          tooltip="Ir para Menu Administrativo"
          onClick={goToAdministrativo}
        ></ButtonPrivateHeader>
      ) : null,
    [isMobile, goToAdministrativo],
  );
  const finalizarComoVenda = useRef<boolean>(false)

  const [situacao, setSituacao] = useState<number>(0);

  const transformarEmVenda = async () => {
    await setComandaId('');
    await setMesaId('');
    await setSalaoId('');
    await setTipoMovimentacao(EnumTipoMovimentacao.VENDA);
    return historyPush('/venda-simples/novo-pagamento');
  }

  const verificarConexao = useCallback(async () => {
    try {
      const rethealth = await getHealth();

      if (rethealth.erro) throw new Error();
    } catch (e: any) {
      const modeloTrabalho = getConfigPDV(101);
      const temFechamento = [
        EnumModeloDeTrabalho.LANCADOR_COM_FECHAMENTO_DE_VENDAS,
      ].includes(modeloTrabalho as EnumModeloDeTrabalho);
      if (temFechamento && (getMov()!.produtos.filter(x => x.ativo)?.length ?? 0) > 0) {
        finalizarComoVenda.current = true;
      }
      throw new Error('Sem conexão com a internet.')
    }
  }, [getConfigPDV, getHealth, getMov])

  const pedidosQueNaoPodemReceberProdutos = [
    EnumStatusPedido.CANCELADO,
    EnumStatusPedido.FECHADO,
    EnumStatusPedido.FINALIZADOOUENTREGUE,
    EnumStatusPedido.IMPORTADO,
  ];

  let limiteReq = 1;

  const iniciarVerificacaoDoPedido = useCallback(async () => {
    finalizarComoVenda.current = false;
    switch (situacao) {
      case 0:
        try {
          const mov = getMov();

          if (!mov) {
            showToast('info', 'nenhuma venda em andamento');
            historyPush('/venda-simples/landing');
            return;
          }

          if (mov.tipoMovimentacao === EnumTipoMovimentacao.VENDA && isEmpty(mov.informacoesGeraisPedido.identificador)) { 
          await setSalaoId('');
            await setMesaId('');
            await setComandaId('');
            await setIdentificadorPager('')
            showToast(
              'info',
              'Você foi redirecionado para o fluxo de venda simples.',
            );
            historyPush('/venda-simples/landing');
            return;
          }
          

          const mesaOuComanda = getConfiguracoesMesaEComanda();
          const balcoes = await getSaloesCadastrados()

          const qtdBalcoes = balcoes.filter(salao => salao.balcao.codigo === EnumBalcaoSalao.UTILIZA)?.length ?? 0

          if (
            mesaOuComanda?.tipoTrabalho === EnumTipoTrabalho.COMANDA &&
            mov.informacoesGeraisPedido.comandaId === '' && mesaOuComanda.qtdeComandas > 0
          ) {
            historyPush('/venda-simples/leitor-comanda');
            return;
          }
          if (
            (mesaOuComanda?.tipoTrabalho === EnumTipoTrabalho.MESA) &&
            (mov.informacoesGeraisPedido.mesaId === '' && !mov.informacoesGeraisPedido.balcaoIdentificado &&
              (mesaOuComanda.qtdeMesa > 0 || qtdBalcoes > 1)
            )
          ) {
            historyPush('/venda-simples/vincular-mesa');
            return;
          }

          // query de busca se for mesa ou comanda
          let query = '';

          if (
            mesaOuComanda?.tipoTrabalho === EnumTipoTrabalho.COMANDA &&
            mov.informacoesGeraisPedido.comandaId
          ) {
            query = `comandaId=${mov.informacoesGeraisPedido.comandaId}`;
          }

          if (
            mesaOuComanda?.tipoTrabalho === EnumTipoTrabalho.MESA &&
            mov.informacoesGeraisPedido.mesaId
          ) {
            query = `mesaId=${mov.informacoesGeraisPedido.mesaId}`;
          }

          await verificarConexao();

          // Aqui eu verifico se a venda não tem id o que significa que não identificamos o pedido anteriormente
          if ((mov.id === '' || mov.id === guidEmpty()) && (
            !isEmpty(mov.informacoesGeraisPedido.comandaId) || !isEmpty(mov.informacoesGeraisPedido.mesaId)
          )) {
            const responsePedidos = await getPedidoSalao(
              getEmpresaSelecionada()!.Id,
              query,
            );

            if (responsePedidos.isTimeout) {
              if (limiteReq <= 2) {
                limiteReq++
                await iniciarVerificacaoDoPedido()
              } else {
                throw new Error(
                  `Não foi possível identificar o pedido, tente novamente. Detalhe: ${responsePedidos.erro}`,
                )
              }
              return
            }

            if (responsePedidos.erro) {
              throw new Error(
                `Não foi possível identificar o pedido, tente novamente. Detalhe: ${responsePedidos.erro}`,
              );
            }

            const pedidos =
              (responsePedidos?.resultado?.data?.list as PedidoModel[]) ?? [];

            // aqui verifico o status do pedido para saber se posso atrelar produtos a ele.
            if (
              pedidos?.length > 0 &&
              pedidosQueNaoPodemReceberProdutos.includes(
                pedidos[0].statusPedido.codigo,
              )
            ) {
              if (
                mesaOuComanda?.tipoTrabalho === EnumTipoTrabalho.COMANDA &&
                mov.informacoesGeraisPedido.comandaId
              ) {
                const comanda = await TouchoneDBPrimary.comandas.get({
                  id: pedidos[0].comandaId,
                });

                showToast(
                  'error',
                  `A comanda ${comanda?.codigoComanda} está no status de '${pedidos[0].statusPedido.descricao}' e não permite lançamentos.`,
                );
                return historyPush('/venda-simples/leitor-comanda');
              }

              if (
                mesaOuComanda?.tipoTrabalho === EnumTipoTrabalho.MESA &&
                mov.informacoesGeraisPedido.mesaId
              ) {
                const mesa = await TouchoneDBPrimary.mesas.get({
                  id: pedidos[0].mesaId,
                });

                showToast(
                  'error',
                  `A mesa ${mesa?.codigo} está no status de '${pedidos[0].statusPedido.descricao}' e não permite lançamentos.`,
                );
                return historyPush('/venda-simples/vincular-mesa');
              }
            }

            let codigoMesa: string = '';

            if (pedidos?.length > 0 && pedidos[0].mesaId) {
              const mesa = await TouchoneDBPrimary.mesas.get({
                id: pedidos[0].mesaId,
              });

              if (mesa) {
                codigoMesa = mesa.codigo ?? '';
              }
            }

            let codigoComanda: string = '';

            if (pedidos?.length > 0 && pedidos[0].comandaId) {
              const comanda = await TouchoneDBPrimary.comandas.get({
                id: pedidos[0].comandaId,
              });

              if (comanda) {
                codigoComanda = comanda.codigoComanda ?? '';
              }
            }

            if (pedidos?.length > 0) {
              await setPedidoExistente(
                pedidos[0].id,
                pedidos[0].comandaId,
                pedidos[0].mesaId,
                pedidos[0].salaoId,
                codigoMesa,
                codigoComanda,
                pedidos[0].codigoPedido || '',
                pedidos[0].identificador || ''
              );
              setSituacao(2);
              return;
            }
            setSituacao(1);
          } else if (mov.id !== '' && mov.id !== guidEmpty()) {
            setSituacao(2);
          } else {
            setSituacao(1);
          }
        } catch (e: any) {
          setError(e);
          return;
        }

        break;
      case 1:
        try {
          const mov = getMov();

          if (!mov) {
            showToast('info', 'Nenhuma venda em andamento');
            historyPush('/venda-simples/landing');
            return;
          }

          const mesaOuComanda = getConfiguracoesMesaEComanda();
          const saloes = await getSaloesCadastrados();
          // aqui eu verifico se meu tipo de trabalho é comanda e eu posso identificar a mesa

          const mesas = mesaOuComanda?.qtdeMesa ?? 0;

          const balcoes = saloes.filter(salao => salao.balcao?.codigo === EnumBalcaoSalao.UTILIZA)
          const semMesasEBalcao = mesas === 0 && (balcoes?.length ?? 0) === 0
          if (mesas > 0 || (balcoes?.length ?? 0) > 0) {
            if (
              mesaOuComanda?.tipoTrabalho === EnumTipoTrabalho.COMANDA &&
              !mov.informacoesGeraisPedido.mesaId &&
              mov.informacoesGeraisPedido.balcaoIdentificado === false &&
              !semMesasEBalcao && (mesas > 0 || (balcoes?.length ?? 0) > 1)
            ) {
              historyPush('/venda-simples/vincular-mesa');
              return;
            } else if ((balcoes?.length ?? 0) === 1 && isEmpty(mov.informacoesGeraisPedido.salaoId)) {
              await setSalaoId(balcoes[0].id || '')
            }
          }

          //aqui verifico se tenho produtos.
          if ((mov.produtos?.length ?? 0) === 0) {
            historyPush('/venda-simples/landing');
            return;
          }

          if (!mov.clienteIdentificado && getConfigByCod(EnumEmpresaConfig.IdentificacaoClienteAbertura) === "1") {
            historyPush('/venda-simples/informacoes-cliente')
            return;
          }

          if (!mov.clienteIdentificado && getConfigPDV(20) === '2') {
            historyPush('/venda-simples/identificar-cliente');
            return;
          }

          const pedido = pedidoDadosWrapper(getMov() || mov); //getMov caso tenha atualizado salaoId para o balcao

          await verificarConexao()

          const postPedido = await postPedidoDados(
            getEmpresaSelecionada()!.Id,
            pedido,
          );

          if (postPedido.erro) {
            throw new Error(
              `Não foi possível criar um pedido, tente novamente. Detalhe: ${postPedido.erro}`,
            );
          }

          const pedidoCriado = postPedido.resultado?.data as PedidoModel;

          let codigoMesa: string = '';

          if (pedidoCriado.mesaId) {
            const mesa = await TouchoneDBPrimary.mesas.get({
              id: pedidoCriado.mesaId,
            });

            if (mesa) {
              codigoMesa = mesa.codigo ?? '';
            }
          }

          let codigoComanda: string = '';

          if (pedidoCriado.comandaId) {
            const comanda = await TouchoneDBPrimary.comandas.get({
              id: pedidoCriado.comandaId,
            });

            if (comanda) {
              codigoComanda = comanda.codigoComanda ?? '';
            }
          }

          await setPedidoExistente(
            pedidoCriado.id,
            pedidoCriado.comandaId,
            pedidoCriado.mesaId,
            pedidoCriado.salaoId,
            codigoMesa,
            codigoComanda,
            pedidoCriado.codigoPedido || '',
            pedidoCriado.identificador || ''
          );

          setSituacao(2);
        } catch (e: any) {
          setError(e);
          return;
        }
        break;
      case 2:
        try {
          const mov = getMov();

          if (!mov) {
            showToast('info', 'Nenhuma venda em andamento');
            historyPush('/venda-simples/landing');
            return;
          }

          if ((mov.produtos?.length ?? 0) === 0) {
            historyPush('/venda-simples/landing');
            return;
          }

          if (!mov.clienteIdentificado && getConfigByCod(EnumEmpresaConfig.IdentificacaoClienteAbertura) === "1") {
            historyPush('/venda-simples/informacoes-cliente')
            return;
          }

          if (!mov.clienteIdentificado && getConfigPDV(20) === '2') {
            historyPush('/venda-simples/identificar-cliente');
            return;
          }

          const receitas = mov.receitasMedicas;

          if((receitas?.length ?? 0) > 0) {
            const resultPostReceitas = await postPedidoReceita(
              getEmpresaSelecionada()!.Id,
              mov.id,
              receitas,
            );
  
            if (resultPostReceitas.erro) {
              throw new Error(
                'Não foi possível enviar as receitas para o pedido, tente novamente.',
              );
            }
          }

          setSituacao(3);
        } catch (e: any) {
          setError(e);
          return;
        }
        break;

      case 3:
        try {
          const mov = getMov();

          if (!mov) {
            showToast('info', 'Nenhuma venda em andamento');
            historyPush('/venda-simples/landing');
            return;
          }

          //aqui verifico se tenho produtos.
          if ((mov.produtos?.length ?? 0) === 0) {
            historyPush('/venda-simples/landing');
            return;
          }

          if (!mov.clienteIdentificado && getConfigByCod(EnumEmpresaConfig.IdentificacaoClienteAbertura) === "1") {
            historyPush('/venda-simples/informacoes-cliente')
            return;
          }

          if (!mov.clienteIdentificado && getConfigPDV(20) === '2') {
            historyPush('/venda-simples/identificar-cliente');
            return;
          }

          const products = mov.produtos
            .filter(
              (x) =>
                x.produtoId !== getContratoConfigByCod(EnumContratoConfig.ProdutoServico)
            )

          const produtos = pedidoProdutosWrapper(
            products,
            mov.informacoesGeraisPedido.salaoId,
          );

          await verificarConexao();

          const resultPostProdutos = await postPedidoProdutos(
            getEmpresaSelecionada()!.Id,
            mov.id,
            produtos,
          );

          if (resultPostProdutos.erro) {
            throw new Error(
              'Não foi possível enviar os produtos do pedido, tente novamente.',
            );
          }

          if (resultPostProdutos.isTimeout) {
            if (limiteReq <= 2) {
              limiteReq++;
              await iniciarVerificacaoDoPedido();
            } else {
              throw new Error(
                `Não foi possível identificar o pedido, tente novamente. Detalhe: ${resultPostProdutos.erro}`,
              )
            }
            return
          }

          setSituacao(4);
        } catch (e: any) {
          setError(e);
          return;
        }
        break;
      case 4: 
        delRegistro(GestaoStorageKeys.MovSimplesAtual, false);
        showToast('success', 'Pedido enviado com sucesso');
        localStorage.removeItem('pedido')
        historyPush({
          pathname: '/venda-simples/landing',
          search: '?pedidos=true',
        });
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [situacao]);

  const checkPermissao = useCallback(async () => {
    if (!getPermissaoBoolean(EnumCodigosPermissoes.REALIZAR_PEDIDOS) && (!localStorage.getItem('pedido') || localStorage.getItem('pedido') === 'false')) {
      abrirSolicitarPermissao(
        async () => {
          await iniciarVerificacaoDoPedido();
          localStorage.setItem('pedido', 'true')
        },
        EnumCodigosPermissoes.REALIZAR_PEDIDOS,
        'Realizar Venda',
        undefined,
        undefined,
        () => {
          historyPush('/venda-simples/carrinho')
        }
      );
      return;
    }

    await iniciarVerificacaoDoPedido();
  }, [abrirSolicitarPermissao, getPermissaoBoolean, historyPush, iniciarVerificacaoDoPedido])

  useEffect(() => {
    (async () => {
      await checkPermissao()
    })();
  }, [abrirSolicitarPermissao, checkPermissao, getPermissaoBoolean, historyPush, iniciarVerificacaoDoPedido]);

  return (
    <>
      <Grid className={movStyles.root}>
        <Grid className={movStyles.header}>
          <PrivatePageHeader leftArea={leftArea()} title="Enviar Pedido" />
        </Grid>
        {carregando && (
          <Grid
            className={classes.containerLoading}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              style={{ display: 'flex', justifyContent: 'center', padding: 32 }}
            >
              <LoadingFinalizacao />
            </Grid>
            <Typography variant="h5" color="primary">
              {situacao === 0 && <>Procurando Pedido</>}
              {situacao === 1 && <>Iniciando um novo Pedido</>}
              {situacao === 2 && <>Enviando Receitas</>}
              {situacao === 3 && <>Enviando Produtos</>}
              {situacao === 4 && <>Finalizando Envio do Pedido</>}
            </Typography>
          </Grid>
        )}
        {!carregando && error && (
          <Grid
            container
            className={classes.containerError}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={10} sm={8} md={4}>
              <Grid className={classes.imageError}>
                <CancelarIcon tipo="GERAL" fill="#D22" />
              </Grid>
              <Typography variant="h5" align="center">
                {error.message}
              </Typography>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth={true}
                disabled={carregando}
                className={classes.btnTentarNovamente}
                onClick={checkPermissao}
              >
                <AtualizarIcon tipo="BUTTON_PRIMARY" />
                Tentar Novamente
              </Button>
              {finalizarComoVenda.current && <Button
                type="submit"
                color="primary"
                variant="outlined"
                fullWidth={true}
                disabled={carregando}
                className={classes.btnTentarNovamente}
                onClick={() => {
                  transformarEmVenda();
                }}
              >
                <VendaIcon tipo="BUTTON" />
                Finalizar como Venda
              </Button>}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default MovEnviarPedidoPage;