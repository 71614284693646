import { Divider, Grid, Typography } from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import { useStyles } from './mov-prod-catalogo-page-styles';
import { VendaHeader } from '../components/produtos/produto-header/produto-header';
import { useDefaultCadastroStyles } from './../../cadastros/components/default-cadastro-styles';
import { MenuTabCategoria } from './components/menu-tab-categoria/menu-tab-categoria';
import { CarrinhoButton } from '../components/buttons-actions/carrinho-button/carrinho-button';
import { FinalizarVendaButton } from '../components/buttons-actions/finalizar-venda-button/finalizar-venda-button';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { ToastLeitor } from '../components/toast-leitor/toast-leitor';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { EnumTipoMovimentacao } from 'model/enums/enum-tipo-movimentacao';

const MovProdCatalogoPage = () => {
  const classes = useDefaultCadastroStyles();
  const utilsClass = useStyles();
  const [openPesquisa, setOpenPesquisa] = useState<boolean>(false);
  const { callEvent, addHandler, removeHandler } = useEventTools();
  const { getMov } = useMovAtual();

  useEffect(() => {
    callEvent(AppEventEnum.PermiteEntradaDigitada, true);
    addHandler(AppEventEnum.TabCategoria, setOpenPesquisa);
    return () => removeHandler(AppEventEnum.TabCategoria, setOpenPesquisa);
  }, [addHandler, callEvent, removeHandler]);

  const toastLeitor = useMemo(() => (
    <ToastLeitor />
  ), [])

  return (
    <Grid className={classes.root}>
      <Grid className={classes.header}>
        <VendaHeader
          title="Catálogo de Produtos"
          id='menuAdm'
        />
      </Grid>

      {getMov() &&
        getMov()?.tipoMovimentacao === EnumTipoMovimentacao.PEDIDO &&
        (getMov()?.informacoesGeraisPedido?.codigoComanda ||
          getMov()?.informacoesGeraisPedido?.codigoMesa ||
          getMov()?.informacoesGeraisPedido?.codigoPedido) &&
        (
          <Grid className={utilsClass.infoPedido}>
            <Typography style={{ textAlign: 'center' }}>
              {' '}
              Você está {' '}
              {getMov()?.informacoesGeraisPedido?.comandaId
                ? ' na Comanda'
                : getMov()?.informacoesGeraisPedido?.mesaId
                  ? 'na Mesa'
                  : getMov()?.informacoesGeraisPedido?.codigoPedido || getMov()?.informacoesGeraisPedido?.identificador
                    ? 'no Pedido'
                    : ''}
              :{' '}
              <span className={utilsClass.textoDestaque}>
                {getMov()?.informacoesGeraisPedido?.comandaId
                  ? `${getMov()?.informacoesGeraisPedido?.codigoComanda}`
                  : getMov()?.informacoesGeraisPedido?.mesaId
                    ? `${getMov()?.informacoesGeraisPedido?.codigoMesa}`
                    : getMov()?.informacoesGeraisPedido?.identificador ?
                      `${getMov()?.informacoesGeraisPedido?.identificador}` :
                      getMov()?.informacoesGeraisPedido?.codigoPedido
                        ? `#${getMov()?.informacoesGeraisPedido?.codigoPedido}`
                        : ''}
              </span>
            </Typography>
            <Divider className={utilsClass.divider} />
          </Grid>
        )}

      <Grid style={{ flex: 1, overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
        <MenuTabCategoria openPesquisa={openPesquisa} />
      </Grid>

      <Grid className={utilsClass.containerFooter}>
        <CarrinhoButton />
        <FinalizarVendaButton />
      </Grid>
      {toastLeitor}
    </Grid>
  );
};

export default MovProdCatalogoPage;
