import { Grid } from "@material-ui/core"
import { VendaCompletaModel } from 'model/api/gestao/venda/venda-completa-model';
import { AccordionSaurus } from "views/components/accordions/accordion-saurus/accordion-saurus"
import { CardProdutosDetalheVenda } from "views/components/cards/card-produtos-detalhe-venda/card-produtos-detalhe-venda";
import { useThemeQueries } from "views/theme"

interface AccordionProdutosProps {
    model: VendaCompletaModel;
}

export const AccordionProdutos = (props: AccordionProdutosProps) => {

    const { theme } = useThemeQueries();

    return (
        <>
            <AccordionSaurus
                labelPrimary={'Produtos'}
                showDivider={true}
                colorDivider={theme.palette.grey[300]}
                style={{ boxShadow: theme.shadows[1] }}
            >
                <Grid container spacing={2} style={{
                    maxHeight: '230px',
                    overflowY: 'auto',
                }}>
                    {props.model.infMov.prod.filter((item) => {
                        return item.cancelado === false
                    }).map((item, index) => {
                        return (
                            <>
                                <Grid item xs={12}>
                                    <CardProdutosDetalheVenda
                                        model={item}
                                        index={index}
                                    />
                                </Grid>
                            </>
                        )
                    })}
                </Grid>
            </AccordionSaurus>
        </>
    )
}