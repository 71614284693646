import { Button, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useStyles } from './finalizar-venda-button-styles';
import { useCadastros, useToastSaurus } from 'services/app';
import { useSessaoAtual } from 'services/app';
import { formatDecimalInteger, toDecimalString } from 'utils/to-decimal';
import { useMovAtual } from 'services/app/hooks/mov-atual';
import { useEventTools } from 'services/app/hooks/events/event-tools';
import { useCallback, useEffect, useRef } from 'react';
import { AppEventEnum } from 'model/enums/enum-app-event';
import { usePDV } from 'services/app/hooks/pdv';
import { EnumModeloDeTrabalho } from 'model/enums/enum-modelo-de-trabalho';
import { usePedidoLocal } from 'services/app/hooks/pedido-local';
import { EnumTipoTrabalho } from 'model/enums/enum-tipo-trabalho';
import { EnumTipoMovimentacao } from 'model/enums/enum-tipo-movimentacao';
import { TouchoneDBPrimary } from 'database/touchone-database';
import { isPlanoControleMesasComandas } from 'utils/plano-utils';
import classNames from 'classnames';
import { useContratoAtual } from 'services/app/hooks/contrato-atual';
import { EnumContratoConfig } from 'model/enums/enum-contrato-config';
import { CircularLoading } from 'views/components';
import { usePedidoDelivery } from 'services/app/hooks/pedido-delivery';
import { EnumBalcaoSalao } from 'model/enums/enum-balcao-salao';
import { EnumStatusSalao } from 'model/enums/enum-status-salao';
import { EnumTpProduto } from 'model/enums/enum-tp-produto';

interface Props {
  disabled?: boolean;
  paddingLeft?: boolean;
  label?: string
  noCarrinho?: boolean;
}

export const FinalizarVendaButton = ({
  disabled,
  paddingLeft = false,
  label,
  noCarrinho
}: Props) => {
  const refFinalizar = useRef<HTMLButtonElement>(null);
  const { getMov, retornaFluxoVenda, setTipoMovimentacao, verificaProdutosControlados, naoInformarDadosDaReceita, getSeUsaIdentificador, carregando } = useMovAtual();
  const { addHandler, removeHandler } = useEventTools();
  const { getConfigByCod } = usePDV();
  const { getConfiguracoesMesaEComanda } = usePedidoLocal();
  const { plano } = useSessaoAtual();
  const { getConfigByCod: getContratoConfigByCod } = useContratoAtual();
  const { abrirTrocarClienteDialog, abrirDialogControlado } = useCadastros()
  const { handleFluxoDelivery, isPedidoDelivery } = usePedidoDelivery()

  const configAtendimento = getConfiguracoesMesaEComanda();

  const hasConfigAtendimento =
    (configAtendimento?.qtdeComandas ?? 0) > 0 ||
    (configAtendimento?.qtdeMesa ?? 0) > 0 ||
    (configAtendimento?.qtdeSaloes ?? 0) > 0;

  const modeloTrabalho = getConfigByCod(101);
  const isLancadorPedidos = [
    EnumModeloDeTrabalho.LANCADOR_COM_FECHAMENTO_DE_VENDAS,
    EnumModeloDeTrabalho.LANCADOR_SEM_FECHAMENTO_DE_VENDAS
  ].includes(modeloTrabalho as EnumModeloDeTrabalho);

  const isSomentePedido = [
    EnumModeloDeTrabalho.LANCADOR_SEM_FECHAMENTO_DE_VENDAS
  ].includes(modeloTrabalho as EnumModeloDeTrabalho);

  const retTxtBotao = useCallback(() => {
    if (label) {
      return label
    }

    const mov = getMov();
    const prodServico = getContratoConfigByCod(EnumContratoConfig.ProdutoServico);

    const qtdProdutos = mov?.produtos.filter(produtoPai => [EnumTpProduto.Produto, EnumTpProduto.ProdutoComSubItem, EnumTpProduto.Combo, EnumTpProduto.Medicamento].includes(produtoPai.tpProduto) && produtoPai.ativo)

    const qtdSomada = qtdProdutos?.reduce((acc, current)=> acc + current.qCom, 0) ?? 0;

    let quantidade = qtdSomada;
    let vNF = mov?.vNF ?? 0;

    const prodTaxaNaVenda = mov?.produtos.filter(
      (prod) => prod.produtoId === prodServico
    );

    const valorProdTaxa =
      prodTaxaNaVenda?.reduce((a, b) => a + b.vFinal, 0) ?? 0;

    if (isSomentePedido || isPedidoDelivery()) {
      vNF = vNF - valorProdTaxa;
    }

    if (prodTaxaNaVenda) {
      quantidade =
        quantidade <= 0
          ? quantidade
          : quantidade - (prodTaxaNaVenda.length ?? 0);
    }

    return `Finalizar ${quantidade > 1 ? formatDecimalInteger(quantidade) + ' itens ' : ''
      } R$ ${toDecimalString(vNF ?? 0, 2)}`;
  }, [getContratoConfigByCod, getMov, isPedidoDelivery, isSomentePedido, label]);

  const movProdAlterado = useCallback(
    (any: any) => {
      if (refFinalizar.current)
        refFinalizar.current.textContent = retTxtBotao();
    },
    [retTxtBotao]
  );

  useEffect(() => {
    addHandler(AppEventEnum.MovAtualProdAlterado, movProdAlterado);
    return () => {
      removeHandler(AppEventEnum.MovAtualProdAlterado, movProdAlterado);
    };
  }, [addHandler, movProdAlterado, removeHandler]);

  const history = useHistory();
  const classes = useStyles();
  const { showToast } = useToastSaurus();

  const handleClickFinalizar = async () => {
    const mov = getMov()
    try {
      if(mov && mov.qCom > 990){
        showToast('error', 'Revise sua venda, limite de 990 produtos/itens.')
        return
      }
      const medicamentos = await verificaProdutosControlados();
      if (medicamentos && medicamentos.length > 0) {
        const qtd = medicamentos.reduce((acc, current) => acc + current.qCom, 0);
        abrirDialogControlado(qtd, naoInformarDadosDaReceita, handleClickFinalizar)
        return
      }

      if (
        !((getMov()?.informacoesGeraisPedido?.pedidos?.length || 0) > 0) &&
        isPlanoControleMesasComandas(plano?.plano)
      ) {
        if (
          isLancadorPedidos &&
          getMov()?.id &&
          (getMov()?.informacoesGeraisPedido?.comandaId ||
            getMov()?.informacoesGeraisPedido?.mesaId ||
            getMov()?.informacoesGeraisPedido.balcaoIdentificado ||
            getMov()?.informacoesGeraisPedido.identificador) &&
          getMov()?.tipoMovimentacao === EnumTipoMovimentacao.PEDIDO
        ) {
          return history.push('/venda-simples/enviar-pedido');
        }
        const saloes = await TouchoneDBPrimary.saloes.toArray()
        const balcoes = saloes.filter(salao => salao.balcao?.codigo === EnumBalcaoSalao.UTILIZA && salao.status?.codigo === EnumStatusSalao.ATIVO)
        if (getConfiguracoesMesaEComanda()?.tipoTrabalho === EnumTipoTrabalho.BALCAO &&
          balcoes.length > 0 && isLancadorPedidos && !getSeUsaIdentificador()) {
          return history.push('/venda-simples/identificador-pedido');
        }
        // aqui verifico se o tipo de caixa é apenas lancador de pedidos
        if (isSomentePedido && getMov()?.tipoMovimentacao === EnumTipoMovimentacao.PEDIDO) {
          if (
            getConfiguracoesMesaEComanda()?.tipoTrabalho ===
            EnumTipoTrabalho.MESA
          ) {
            await setTipoMovimentacao(EnumTipoMovimentacao.PEDIDO);
            return history.push('/venda-simples/vincular-mesa');
          } else if (
            getConfiguracoesMesaEComanda()?.tipoTrabalho ===
            EnumTipoTrabalho.COMANDA
          ) {
            await setTipoMovimentacao(EnumTipoMovimentacao.PEDIDO);
            return history.push('/venda-simples/leitor-comanda');
          } else if (!getSeUsaIdentificador()) {
            return history.push('/venda-simples/identificador-pedido');
          }
        }

        if (
          hasConfigAtendimento &&
          isLancadorPedidos &&
          getMov()?.vNF &&
          getMov()!.vNF > 0 &&
          getMov()?.tipoMovimentacao === EnumTipoMovimentacao.PEDIDO
        ) {
          if (
            getConfiguracoesMesaEComanda()?.tipoTrabalho ===
            EnumTipoTrabalho.COMANDA
          ) {
            setTipoMovimentacao(EnumTipoMovimentacao.PEDIDO);
            history.push('/venda-simples/leitor-comanda');
            return;
          } else if (getConfiguracoesMesaEComanda()?.tipoTrabalho ===
            EnumTipoTrabalho.MESA) {
            setTipoMovimentacao(EnumTipoMovimentacao.PEDIDO);
            history.push('/venda-simples/vincular-mesa');
            return;
          } else if (!getSeUsaIdentificador()) {
            return history.push('/venda-simples/identificador-pedido');
          }
        }
      }

      const ret = await retornaFluxoVenda();
      if (ret.error) {
        showToast('error', ret.msg);
      }
      history.push(ret.url);
    } catch (e: any) {
      showToast('error', e.message);
    }
  };

  const verificarClientes = async () => {
    const mov = getMov()!;
    /*
      AQUI VERIFICA SE EXISTE + DE 1 PEDIDO E SE O PEDIDO CONTEM ALGUM
      CLIENTE IDENTIFICADO E SE ESSE CLIENTE IDENTIFICADO JÁ NÃO ESTÁ COMO
      CLIENTE CADASTRADO DA MOV. CASO O CLIENTE IDENTIFICADO NA MOV SEJA
      O CLIENTE PADRÃO, ISSO É TRATADO DENTRO DO DIALOG
    */
    if (
      mov.informacoesGeraisPedido.pedidos.length > 1 &&
      mov.informacoesGeraisPedido.pedidos.filter(pedido => (
        pedido.cliente.cpfCnpj !== '99999999000191' &&
        pedido.cliente.nomeFantasia !== mov.cliente?.nome
      )).length > 0
    ) {
      abrirTrocarClienteDialog(handleClickFinalizar)
      return
    }

    if (isPedidoDelivery()) {
      handleFluxoDelivery(noCarrinho)
      return
    }
    await handleClickFinalizar()
  }

  return (
    <>
      <Grid
        item
        className={classNames(
          classes.containerItemFooter,
          paddingLeft ? classes.containerItemFooterLeft : undefined
        )}
      >
        {carregando && <CircularLoading tipo="FULLSIZED" />}
        <Button
          fullWidth
          ref={refFinalizar}
          variant="contained"
          color="primary"
          disabled={disabled}
          className={classNames(classes.textPreview, 'round')}
          onClick={async () => {
            if (!carregando) {
              await verificarClientes();
            }
          }}
        >
          {retTxtBotao()}
        </Button>
      </Grid>
    </>
  );
};
